import { REGISTER_ACTIONS, RegisterActions } from "./register.types"

export const setFirebaseRegisterToken = (
    token: string,
    mail?: string,
): RegisterActions => ({
    type: REGISTER_ACTIONS.SET_TOKEN,
    payload: {
        token,
        mail,
    },
})

export const clearAllFirebaseRegister = (): RegisterActions => ({
    type: REGISTER_ACTIONS.CLEAR_ALL,
})

export const setCredentialsRegister = (
    mail: string,
    password: string,
): RegisterActions => ({
    type: REGISTER_ACTIONS.SET_CREDENTIALS,
    payload: {
        mail,
        password,
    },
})

import { AbstractLogin, ACTION_TO_BE_DONE } from "./abstract-login"

export class GoogleLogin extends AbstractLogin {
    public async login(): Promise<{
        actionToBeDone: ACTION_TO_BE_DONE
        firebaseToken?: string
    }> {
        try {
            const googleProvider = new this.auth.GoogleAuthProvider()
            const credentials = await this.auth().signInWithPopup(googleProvider)
            return this.actionToBeDone(credentials)
        } catch (e) {
            throw e
        }
    }
}

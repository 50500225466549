export enum SAGA_TYPE {
    CALENDAR_EVENT_UPDATE = "CALENDAR_EVENT_UPDATE",
    DISABLE_CLICK_OUTSIDE = "DISABLE_CLICK_OUTSIDE",
    WIDGET_UPDATE = "WIDGET_UPDATE",
}

export interface SagaPayload {
    type: SAGA_TYPE
    payload?: any
}

export interface SagaPayloadType {
    [SAGA_TYPE.CALENDAR_EVENT_UPDATE]: {
        year: number | "*"
        month: number | "*"
        day: number | "*" // * if any
    }
    [SAGA_TYPE.DISABLE_CLICK_OUTSIDE]: {
        disable: boolean
        id: string
    }
    [SAGA_TYPE.WIDGET_UPDATE]: {}
}

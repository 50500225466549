import React from "react"

import { HashRouter, Route } from "react-router-dom"
import Assets from "./assets/Assets"
import Login from "./login/Login"
import Register from "./register/Register"
import AppRouter from "./app/Router"
import { routes } from "helpers/routes"
import Landing from "./landing/Landing"

const Router: React.FC = () => {
    return (
        <div>
            <HashRouter>
                <Route path={routes.login}>
                    <Login />
                </Route>
                <Route path={routes.assets}>
                    <Assets />
                </Route>
                <Route path={routes.register}>
                    <Register />
                </Route>
                <Route path={routes.app.index}>
                    <AppRouter />
                </Route>
                <Route path={routes.index} exact>
                    <Landing />
                </Route>
            </HashRouter>
        </div>
    )
}

export default Router

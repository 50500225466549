import { EventsController } from "controllers/events/events"
import { EventInterface } from "@planify/lib-type"

export class EventFactory {
    public async getAllEventOfDay(
        day: number,
        month: number,
        year: number,
    ): Promise<EventInterface[]> {
        const events = await new EventsController().getEventMonth(month, year)
        return events.filter(
            event =>
                event.date.getFullYear() === year &&
                event.date.getMonth() === month &&
                event.date.getDate() === day,
        )
    }
}

import {
    RecurringExpenseInterface,
    EventInterface,
    RECURRING_EXPENSE_GRANULARITY,
} from "@planify/lib-type"

import moment from "moment"

const getNextDateFromDateAndGranularity = (
    date: moment.Moment,
    i: number,
    granularity: RECURRING_EXPENSE_GRANULARITY,
    eachGranularity: number,
): moment.Moment => {
    const nextDate = date.clone()

    switch (granularity) {
        case RECURRING_EXPENSE_GRANULARITY.DAYS:
            nextDate.add(eachGranularity * i, "day")
            break
        case RECURRING_EXPENSE_GRANULARITY.WEEKS:
            nextDate.add(eachGranularity * i, "week")
            break
        case RECURRING_EXPENSE_GRANULARITY.MONTH:
            nextDate.add(eachGranularity * i, "month")
            break
        case RECURRING_EXPENSE_GRANULARITY.YEARS:
            nextDate.add(eachGranularity * i, "year")
            break
    }
    return nextDate
}

export const recurringExpenseFromDateToDateToListOfEvents = (
    recurringExpense: RecurringExpenseInterface,
    startDate: moment.Moment,
    endDate: moment.Moment,
): EventInterface[] => {
    const nowDate = moment()
    nowDate.startOf("day")

    if (endDate.isBefore(nowDate)) {
        return []
    }

    const eventList: EventInterface[] = []

    const initialDate = moment(recurringExpense.beginDate).startOf("day")

    let nextDate = initialDate.clone()

    let i = 1

    while (nextDate.isBefore(endDate)) {
        if (nextDate.isSameOrAfter(startDate) && nextDate.isSameOrAfter(nowDate)) {
            eventList.push({
                id: recurringExpense.id,
                amount: recurringExpense.amount,
                category: recurringExpense.category,
                color: recurringExpense.color,
                date: nextDate.toDate(),
                name: recurringExpense.name,
                isRecurring: true,
            })
        }

        nextDate = getNextDateFromDateAndGranularity(
            initialDate,
            i++,
            recurringExpense.granularity,
            recurringExpense.eachGranularity,
        )
    }

    return eventList
}

export const recurringsToEvents = async (
    recurrings: RecurringExpenseInterface[],
    start: moment.Moment,
    end: moment.Moment,
): Promise<EventInterface[]> => {
    return recurrings
        .map(event => recurringExpenseFromDateToDateToListOfEvents(event, start, end))
        .reduce((prev, curr) => [...prev, ...curr], [])
}

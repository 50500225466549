import { AbstractController } from "controllers/abtract-controller"
import { EventInterface } from "@planify/lib-type/build"

export class EventsController extends AbstractController {
    protected path = "events"

    /**
     * Add an event
     * @param event The event
     * @return The id of the created event
     */
    public async addEvent(event: Omit<EventInterface, "id">): Promise<string> {
        return this.doPostRequest("/addEvent", event)
    }

    private convertEventFromAPI(events: EventInterface[]): EventInterface[] {
        return events.map(event => ({
            ...event,
            date: new Date(event.date),
        }))
    }

    public async allEventsFromUser(): Promise<EventInterface[]> {
        const events: EventInterface[] = await this.doGetRequest("/list")

        return this.convertEventFromAPI(events)
    }

    public async getEventMonth(month: number, year: number): Promise<EventInterface[]> {
        const events: EventInterface[] = await this.doGetRequest("/getMonth", {
            params: {
                month,
                year,
            },
        })

        return this.convertEventFromAPI(events)
    }

    public async deleteEventFromUser(eventId: string) {
        await this.doDeleteRequest(`/${eventId}`)
    }

    public async expensesFromNowToDate(
        fromDate: Date,
        toDate: Date,
    ): Promise<Record<string, number>> {
        return await this.doGetRequest("/expensesByCategoryByDate", {
            params: {
                fromDate,
                toDate,
            },
        })
    }
}

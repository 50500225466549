import React, { useState } from "react"
import Planify from "assets/logo/Logo.svg"
import styles from "./Login.module.scss"
import { Input, Button } from "assets"
import { FiArrowRight } from "react-icons/fi"
import { Link } from "react-router-dom"
import { MailLogin } from "helpers/login/mail-login"
import { ACTION_TO_BE_DONE } from "helpers/login/abstract-login"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { initAction } from "redux/user/user.actions"
import { useToasts } from "react-toast-notifications"
import {
    setFirebaseRegisterToken,
    setCredentialsRegister,
} from "redux/register/register.actions"
import { routes } from "helpers/routes"
import { GoogleLogin } from "helpers/login/google-login"
import { FacebookLogin } from "helpers/login/facebook-login"

const LoginBase: React.FC = () => {
    const { addToast } = useToasts()
    const [mail, setMail] = useState("")
    const [password, setPassword] = useState("")
    let history = useHistory()
    const dispatcher = useDispatch()

    const handleAfterLogin = (action: ACTION_TO_BE_DONE, firebaseToken?: string) => {
        switch (action) {
            case ACTION_TO_BE_DONE.SUCCESS: {
                dispatcher(initAction())
                history.push(routes.app.index)
                break
            }
            case ACTION_TO_BE_DONE.NOT_FOUND: {
                dispatcher(setCredentialsRegister(mail, password))
                history.push(routes.register)
                break
            }
            case ACTION_TO_BE_DONE.REGISTER: {
                if (firebaseToken) {
                    dispatcher(setFirebaseRegisterToken(firebaseToken, mail))
                    history.push(routes.register)
                }
                break
            }
            case ACTION_TO_BE_DONE.ERROR: {
                addToast("Erreur lors de l'authentification", {
                    appearance: "error",
                    autoDismiss: true,
                })
            }
        }
    }

    const handleClickMail = async () => {
        const mailLogin: MailLogin = new MailLogin()

        let action: ACTION_TO_BE_DONE
        let firebaseToken: string | undefined

        try {
            const {
                actionToBeDone,
                firebaseToken: _firebaseToken,
            } = await mailLogin.login(mail, password)

            action = actionToBeDone
            firebaseToken = _firebaseToken
        } catch (e) {
            addToast(e.message || e, { appearance: "error", autoDismiss: true })
            return
        }

        handleAfterLogin(action, firebaseToken)
    }

    const handleClickGoogle = async () => {
        const googleLogin = new GoogleLogin()

        let action: ACTION_TO_BE_DONE
        let firebaseToken: string | undefined

        try {
            const {
                actionToBeDone,
                firebaseToken: _firebaseToken,
            } = await googleLogin.login()
            action = actionToBeDone
            firebaseToken = _firebaseToken
        } catch (e) {
            addToast(e.message || e, { appearance: "error", autoDismiss: true })
            return
        }

        handleAfterLogin(action, firebaseToken)
    }

    const handleClickFacebook = async () => {
        const facebookLogin = new FacebookLogin()

        let action: ACTION_TO_BE_DONE
        let firebaseToken: string | undefined

        try {
            const {
                actionToBeDone,
                firebaseToken: _firebaseToken,
            } = await facebookLogin.login()
            action = actionToBeDone
            firebaseToken = _firebaseToken
        } catch (e) {
            addToast(e.message || e, { appearance: "error", autoDismiss: true })
            return
        }

        handleAfterLogin(action, firebaseToken)
    }

    return (
        <div className={styles.rootBase}>
            <div className="container">
                <div className={styles.logo}>
                    <img src={Planify} alt="Planify logo" />
                </div>
                <form>
                    <div className={styles.labelConexion}>Conexion</div>
                    <div className={styles.input}>
                        <Input label="Mail" value={mail} onChange={setMail} />
                    </div>
                    <div className={styles.input}>
                        <Input
                            label="Mot de passe"
                            type="password"
                            value={password}
                            onChange={setPassword}
                        />
                    </div>
                    <Button
                        className={styles.button}
                        theme="primary"
                        icon={<FiArrowRight />}
                        onClick={handleClickMail}
                    >
                        Se connecter
                    </Button>
                </form>

                <div className={styles.line} />
                <div className={styles.loginProviders}>
                    <div className="columns">
                        <div className="column">
                            <Button
                                className={styles.providerGoogle}
                                icon={<FiArrowRight />}
                                onClick={handleClickGoogle}
                            >
                                Google
                            </Button>
                        </div>
                        <div className="column">
                            <Button
                                className={styles.providerFacebook}
                                icon={<FiArrowRight />}
                                onClick={handleClickFacebook}
                            >
                                Facebook
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <div className="container">
                    <Link className={styles.footerText} to="/register">
                        Créer votre compte
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default LoginBase

import React, { useState } from "react"
import { useWindowWidth } from "helpers/hooks"
import styles from "./Settings.module.scss"

import {
    createMuiTheme,
    FormControl,
    FormControlLabel,
    FormGroup,
    MuiThemeProvider,
    Switch,
} from "@material-ui/core"
import { toogleDarkMode } from "redux/config/config.actions"
import { useDispatch } from "react-redux"
import { store } from "redux/store"
import { Button } from "assets"
import { signout } from "helpers/jwt/signout"

export const GeneralSettings: React.FC = () => {
    const width = useWindowWidth()

    const dispatcher = useDispatch()
    const [isDarkMode, setIsDarkMode] = useState(store.getState().config.isDarkMode)

    const darkModeTrigger = () => {
        setIsDarkMode(!isDarkMode)
        return toogleDarkMode()
    }

    const signOut = async () => {
        await signout()
    }

    const theme = createMuiTheme({
        palette: {
            primary: {
                main: styles.primary,
            },
        },
    })

    return (
        <div
            className={
                width < 500 ? styles.navItemMobileSettings : styles.navItemDesktopSettings
            }
        >
            <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                    <FormControlLabel
                        control={
                            <React.Fragment>
                                <MuiThemeProvider theme={theme}>
                                    <Switch
                                        checked={isDarkMode}
                                        onChange={() => dispatcher(darkModeTrigger())}
                                        color="primary"
                                    />
                                </MuiThemeProvider>
                            </React.Fragment>
                        }
                        label={<label className={styles.label}>Dark mode</label>}
                        labelPlacement="start"
                    />
                </FormGroup>
            </FormControl>
            <div className={styles.logOut}>
                <label className={styles.label}>Se déconnecter</label>
                <Button onClick={signOut}>Déconnexion</Button>
            </div>
        </div>
    )
}

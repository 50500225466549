import React, { useState, useRef } from "react"
import { BaseProps, useBaseProps } from "assets/helpers/base-component"

import styles from "./ColorPicker.module.scss"
import { FiMousePointer } from "react-icons/fi"
import { useDetectClickOutside } from "helpers/hooks"

interface props extends BaseProps {
    value?: string
    options: readonly string[]
    onChange: (value: string) => void
}

export const ColorPicker: React.FC<props> = ({ value, options, onChange, ...props }) => {
    const rootProps = useBaseProps(props, styles.root)

    const [showOption, setShowOptions] = useState(false)
    const panelRef = useRef<HTMLDivElement>(null)
    useDetectClickOutside(panelRef, showOption, () => {
        setShowOptions(false)
    })

    return (
        <div {...rootProps}>
            <div
                className={styles.square}
                onClick={() => setShowOptions(!showOption)}
                style={{ backgroundColor: value || undefined }}
            >
                <div className={styles.iconPick}>
                    <FiMousePointer />
                </div>
            </div>
            <div
                className={styles.picker}
                ref={panelRef}
                style={{ display: showOption ? "flex" : "none", zIndex: 20 }}
            >
                {options.map(option => (
                    <div
                        key={option}
                        className={styles.option}
                        style={{ backgroundColor: option }}
                        onClick={() => {
                            onChange(option)
                            setShowOptions(false)
                        }}
                    >
                        <div className={styles.hoverEvent} />
                    </div>
                ))}
            </div>
        </div>
    )
}

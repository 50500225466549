import { store } from "redux/store"
import { fillAction, errorAction } from "redux/user/user.actions"
import { UserController } from "controllers/users/users"

export const initRedux = async () => {
    try {
        const data = await new UserController().getMe()
        store.dispatch(fillAction(data))
    } catch {
        store.dispatch(errorAction())
    }
}

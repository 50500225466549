import React from "react"
import { BaseProps, useBaseProps } from "assets/helpers/base-component"
import styles from "./MainButton.module.scss"

interface props extends BaseProps {
    onClick?: () => void
    children?: any
    icon?: React.ReactNode
    theme?: "primary" | "default"
    small?: boolean
}

export const Button: React.FC<props> = ({
    onClick,
    children,
    icon,
    theme,
    small,
    ...props
}) => {
    if (!theme) {
        theme = "default"
    }

    const rootProps = useBaseProps(
        props,
        `${styles[`root-${theme}`]} ${small ? styles.small : ""}`,
    )

    return (
        <button
            {...rootProps}
            onClick={e => {
                e.preventDefault()
                onClick && onClick()
            }}
        >
            <div className={styles.container}>
                <div className={styles.text}>{children}</div>
                {icon && <div className={`${styles.icon}`}>{icon}</div>}
            </div>
        </button>
    )
}

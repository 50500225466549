import React, { useEffect } from "react"
import { FiHome, FiBarChart, FiCalendar } from "react-icons/fi"
import { Menu, Fallback } from "assets"
import { routes } from "helpers/routes"
import { useHistory, Route, useLocation, Redirect } from "react-router-dom"
import Dashboard from "./dashboard/Dashboard"
import { initRedux } from "helpers/user/init-redux"
import { useSelector } from "react-redux"
import { StoreType } from "redux/store"
import { LOAD_STATE } from "redux/user/user.type"
import StatsBoard from "./stats/Stats"
import { Settings } from "pages/settings/Settings"
import Calendar from "./calendar/Calendar"
import { useRouteArray } from "./route.hooks"

const itemMenu = {
    dashboard: { value: "Dashboard", icon: <FiHome /> },
    stats: { value: "Statistiques", icon: <FiBarChart /> },
    calendar: { value: "Calendrier", icon: <FiCalendar /> },
}

const Router: React.FC = () => {
    const history = useHistory()
    const actualRoute = useLocation()
    const userInfos = useSelector<StoreType, StoreType["user"]>(state => state.user)

    const currentRoute = useRouteArray(actualRoute.pathname)

    useEffect(() => {
        if (actualRoute.pathname === routes.app.index || currentRoute === undefined) {
            history.push(routes.app.dashboard)
        }
    }, [history, actualRoute.pathname, currentRoute])

    const setActiveMenuNav = (menu: keyof typeof itemMenu) => {
        const routePath = routes.app[menu]
        history.push(routePath)
    }

    useEffect(() => {
        if (userInfos.state === LOAD_STATE.NOT_LOADED) {
            initRedux()
        }
    }, [userInfos.state])

    if (userInfos.state === LOAD_STATE.NOT_LOADED) {
        return <Fallback />
    }

    if (userInfos.state === LOAD_STATE.ERROR) {
        return <Redirect to="/login" />
    }

    return (
        <div>
            <Menu
                items={itemMenu}
                onChange={newVal => setActiveMenuNav(newVal)}
                value={currentRoute && currentRoute[1]}
            />
            <div>
                <Route path={routes.app.dashboard}>
                    <Dashboard />
                </Route>
                <Route path={routes.app.stats}>
                    <StatsBoard />
                </Route>
                <Route path={routes.app.settings.index}>
                    <Settings />
                </Route>
                <Route path={routes.app.calendar}>
                    <Calendar />
                </Route>
            </div>
        </div>
    )
}

export default Router

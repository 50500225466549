import { useMemo, useState, useEffect, useCallback } from "react"
import { EventsController } from "controllers/events/events"
import { RecurringExpenseController } from "controllers/recurring-expenses/recurring-expenses"
import { EventInterface, RecurringExpenseInterface } from "@planify/lib-type"
import { useSagaSubscribe, SAGA_TYPE } from "helpers/saga"
import { recurringsToEvents } from "helpers/events"
import moment from "moment"

export const useEventList = (month: number, year: number) => {
    const [events, setEvents] = useState<EventInterface[]>([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const handleLoad = useCallback(async () => {
        let eventsData: EventInterface[]
        let recurringEventData: RecurringExpenseInterface[]

        setLoading(true)
        setError(true)

        try {
            // Get all events
            const eventsDataPromise = new EventsController().getEventMonth(month, year)
            const recurringDataPromise = new RecurringExpenseController().allRecurringExpensesFromUser()

            eventsData = await eventsDataPromise
            recurringEventData = await recurringDataPromise
        } catch {
            setError(true)
            setLoading(false)
            return
        }

        // Filter events to only have events from current month and current year
        const filteredEvents = eventsData.filter(event => {
            return event.date.getMonth() === month && event.date.getFullYear() === year
        })

        const startDate = moment()
        startDate.set("year", year)
        startDate.set("month", month)
        startDate.startOf("month")

        const endDate = startDate.clone().add(1, "month")

        const eventsReccuring = await recurringsToEvents(
            recurringEventData,
            startDate,
            endDate,
        )

        setLoading(false)
        setEvents([...filteredEvents, ...eventsReccuring])
    }, [month, year])

    useEffect(() => {
        handleLoad()
    }, [handleLoad])

    useSagaSubscribe(SAGA_TYPE.CALENDAR_EVENT_UPDATE, handleLoad)

    return {
        loading,
        error,
        events,
        reload: () => handleLoad(),
    }
}

export const useDayListMapper = (startAfter: number, nbDays: number) => {
    const dayListRes = useMemo(() => {
        let dayList: number[] = Array(startAfter).fill(0)
        dayList = [
            ...dayList,
            ...Array(nbDays)
                .fill(0)
                .map((_, i) => i + 1),
        ]

        let dayListMaped = dayList.reduce((prev, curr, i) => {
            if (i % 7 === 0) {
                return [...prev, [curr]]
            }

            prev[prev.length - 1] = [...prev[prev.length - 1], curr]

            return prev
        }, [] as number[][])

        while (dayListMaped[dayListMaped.length - 1].length < 7) {
            dayListMaped[dayListMaped.length - 1] = [
                ...dayListMaped[dayListMaped.length - 1],
                0,
            ]
        }

        return dayListMaped
    }, [startAfter, nbDays])

    return dayListRes
}

import { AxiosInstance, AxiosRequestConfig } from "axios"
import { Paxios, Laxios } from "helpers/axios"

export abstract class AbstractController {
    protected axios: AxiosInstance
    protected abstract path: string
    protected laxios = Laxios

    constructor() {
        this.axios = Paxios()
    }

    private getPath(route: string) {
        return `${this.path}${route}`
    }

    protected async doGetRequest(
        route: string,
        config?: AxiosRequestConfig,
    ): Promise<any> {
        return (
            await this.laxios().apply(axios => axios.get(this.getPath(route), config))
        ).data
    }

    protected async doPostRequest(
        route: string,
        data?: any,
        config?: AxiosRequestConfig,
    ): Promise<any> {
        return (
            await this.laxios().apply(axios =>
                axios.post(this.getPath(route), data, config),
            )
        ).data
    }

    protected async doDeleteRequest(
        route: string,
        data?: any,
        config?: AxiosRequestConfig,
    ): Promise<any> {
        return (
            await this.laxios().apply(axios => axios.delete(this.getPath(route), config))
        ).data
    }
}

export enum REGISTER_STATE {
    EMPTY = "EMPTY",
    TOKEN = "TOKEN",
    CREDENTIALS = "CREDENTIALS",
}

interface EmptyState {
    state: REGISTER_STATE.EMPTY
}

interface TokenState {
    state: REGISTER_STATE.TOKEN
    token: string
    mail?: string
}

interface CredentialState {
    state: REGISTER_STATE.CREDENTIALS
    mail: string
    password: string
}

export type RegisterState = EmptyState | TokenState | CredentialState

export enum REGISTER_ACTIONS {
    SET_TOKEN = "REGISTER/SET_TOKEN",
    CLEAR_ALL = "REGISTER/CLEAR_ALL",
    SET_CREDENTIALS = "REGISTER/SET_CREDENTIALS",
}

interface SetTokenAction {
    type: REGISTER_ACTIONS.SET_TOKEN
    payload: {
        token: string
        mail?: string
    }
}

interface ClearTokenAction {
    type: REGISTER_ACTIONS.CLEAR_ALL
}

interface SetCredentials {
    type: REGISTER_ACTIONS.SET_CREDENTIALS
    payload: {
        mail: string
        password: string
    }
}

export type RegisterActions = SetTokenAction | ClearTokenAction | SetCredentials

import { DAYS, MONTHS } from "@planify/lib-type/build"
import React, { useMemo, useState } from "react"
import { FiChevronLeft, FiChevronRight, FiHome } from "react-icons/fi"
import styles from "./Calendar.module.scss"
import { useEventList, useDayListMapper } from "helpers/calendar/CalendarWidget.hooks"
import { CalendarHelper } from "helpers/calendar"
import DayItem from "./DayItem"
import { AddEvent } from "assets/calendar/add-event"
import { Modal } from "assets/modal"

export default () => {
    const mappedDays = useMemo(() => {
        return DAYS.map(day => {
            return day.toLowerCase().slice(0, 3) + "."
        })
    }, [])

    const [offset, setOffset] = useState(0)

    const nowDate = useMemo(() => {
        return new Date()
    }, [])

    const todayDate = useMemo(() => {
        const offsetDate = new Date(nowDate.getTime())
        offsetDate.setMonth(offsetDate.getMonth() + offset)
        return offsetDate
    }, [nowDate, offset])

    const year = useMemo(() => todayDate.getFullYear(), [todayDate])

    const month = useMemo(() => todayDate.getMonth(), [todayDate])

    const { events } = useEventList(month, todayDate.getFullYear())

    const dayListMemo = useDayListMapper(
        CalendarHelper.getIndexOfFirstDayOfMonthGivenDate(todayDate),
        CalendarHelper.getNbDaysInMonthGivenDate(todayDate),
    )

    const mappedEvents = useMemo(() => {
        return dayListMemo.map(days => {
            return days.map(day => {
                if (day === 0) {
                    return []
                }

                return events.filter(event => event.date.getDate() === day)
            })
        })
    }, [dayListMemo, events])

    const [focusDay, setFocusDay] = useState(1)
    const [openModal, setOpenModal] = useState(false)

    return (
        <div className={styles.root}>
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <AddEvent day={focusDay} month={month} year={year} />
            </Modal>
            <div className={styles.header}>
                <div className={styles.headerInfo}>
                    <div className={styles.title}>Calendrier</div>
                    <div className={styles.monthYear}>
                        {MONTHS[month]} {year}
                    </div>
                </div>
                <div className={styles.focusToday} onClick={() => setOffset(0)}>
                    <div>
                        <FiHome />
                    </div>
                    <div>Aujourd'hui</div>
                </div>
            </div>
            <div className={styles.calendarContainer}>
                <div className={styles.minusMonth} onClick={() => setOffset(offset - 1)}>
                    <FiChevronLeft />
                </div>
                <div className={styles.calendar}>
                    <div className={styles.days}>
                        {mappedDays.map(day => (
                            <div key={day}>{day}</div>
                        ))}
                    </div>
                    <div className={styles.listEvents}>
                        {dayListMemo.map((dayline, i) => (
                            <div key={i} className={styles.dayLine}>
                                {dayline.map((dayElm, j) => (
                                    <div key={`${dayElm}-${j}`} className={styles.dayElm}>
                                        {dayElm !== 0 && (
                                            <div className={styles.dayValid}>
                                                <DayItem
                                                    day={dayElm}
                                                    month={month}
                                                    year={year}
                                                    events={mappedEvents[i][j]}
                                                    onClick={() => {
                                                        setFocusDay(dayElm)
                                                        setOpenModal(true)
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.plusMonth} onClick={() => setOffset(offset + 1)}>
                    <FiChevronRight />
                </div>
            </div>
        </div>
    )
}

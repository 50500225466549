import { Modal } from "./Modal"
import React from "react"
import { BaseProps } from "assets/helpers/base-component"
import styles from "./ContextModal.module.scss"

interface props extends BaseProps {
    open?: boolean
    onClose?: () => void
    title?: string
}

export const ContextModal: React.FC<props> = ({
    open,
    onClose,
    children,
    title,
    ...props
}) => {
    return (
        <Modal onClose={onClose} open={open} {...props}>
            {title && <div className={styles.title}>{title}</div>}
            <div className={styles.wrapper}>
                <div className={styles.content}>{children}</div>
            </div>
        </Modal>
    )
}

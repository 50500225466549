import { AbstractRegister, ACTION_TO_BE_DONE } from "./abstract-register"
import { BANK_TYPE, UserInterface } from "@planify/lib-type/build"

export class TokenRegister extends AbstractRegister {
    public async register(
        token: string,
        firstname: string,
        bank: BANK_TYPE,
    ): Promise<ACTION_TO_BE_DONE> {
        const userModelDTO: Omit<UserInterface, "id" | "mail"> = {
            firstName: firstname,
            bank: bank,
            currentAmount: 0,
            dashboardOrder: [],
        }

        return this.registerUsingToken(token, userModelDTO)
    }
}

import React, { useState, lazy, Suspense, useCallback } from "react"
// import styles from "./Register.module.scss"
import { useWindowWidth } from "helpers/hooks"
import { MailRegister } from "helpers/register/mail-register"
import { BANK_TYPE } from "@planify/lib-type"
import { ACTION_TO_BE_DONE } from "helpers/register/abstract-register"
import { ACTION_TO_BE_DONE as ACTION_TO_BE_DONE_LOGIN } from "helpers/login/abstract-login"
import { useHistory } from "react-router-dom"
import { Fallback } from "assets"
import { useDispatch, useSelector } from "react-redux"
import { initAction } from "redux/user/user.actions"
import { StoreType } from "redux/store"
import { REGISTER_STATE } from "redux/register/register.types"
import { TokenRegister } from "helpers/register/token-register"
import { useToasts } from "react-toast-notifications"
import { GoogleLogin } from "helpers/login/google-login"
import { routes } from "helpers/routes"
import { setFirebaseRegisterToken } from "redux/register/register.actions"
import { FacebookLogin } from "helpers/login/facebook-login"
const RegisterDesktop = lazy(() => import("./Register.desktop"))
const RegisterMobile = lazy(() => import("./Register.mobile"))

const Register: React.FC = () => {
    const width = useWindowWidth()
    const { addToast } = useToasts()
    const [name, setName] = useState<string>("")
    const [firstname, setFirstname] = useState<string>("")
    const [bank, setBank] = useState<BANK_TYPE>()
    const [mail, setMail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const dispatcher = useDispatch()
    let history = useHistory()
    const registerState = useSelector<StoreType, StoreType["register"]>(
        state => state.register,
    )

    const handleSubmit = async () => {
        if (!bank) {
            addToast("Veuillez selectionner une banque", {
                appearance: "error",
                autoDismiss: true,
            })
            return
        }
        let action: ACTION_TO_BE_DONE

        if (registerState.state === REGISTER_STATE.TOKEN) {
            action = await new TokenRegister().register(
                registerState.token,
                firstname,
                bank,
            )
        } else {
            let finalMail: string
            let finalPassword: string

            if (registerState.state === REGISTER_STATE.CREDENTIALS) {
                finalMail = registerState.mail
                finalPassword = registerState.password
            } else {
                finalMail = mail
                finalPassword = password
            }

            const mailRegister: MailRegister = new MailRegister()
            action = await mailRegister.register(
                finalMail,
                finalPassword,
                firstname,
                bank,
            )
        }

        switch (action) {
            case ACTION_TO_BE_DONE.SUCCESS: {
                dispatcher(initAction())
                history.push("/app")
                break
            }
            case ACTION_TO_BE_DONE.MAIL_ALREADY_EXISTS: {
                addToast("Ce compte existe déjà. Veuillez-vous connecter", {
                    autoDismiss: true,
                    appearance: "error",
                })
                break
            }
            case ACTION_TO_BE_DONE.ERROR: {
                addToast("Error lors de la création de votre compte", {
                    autoDismiss: true,
                    appearance: "error",
                })
                break
            }
        }
    }

    const handleRegisterProvider = useCallback(
        async (action: ACTION_TO_BE_DONE_LOGIN, token: string | undefined) => {
            switch (action) {
                case ACTION_TO_BE_DONE_LOGIN.SUCCESS:
                    dispatcher(initAction())
                    history.push(routes.app.index)
                    break
                case ACTION_TO_BE_DONE_LOGIN.REGISTER:
                    if (token) {
                        dispatcher(setFirebaseRegisterToken(token))
                    }
                    break
                default:
                    addToast("Erreur", { appearance: "error", autoDismiss: true })
                    break
            }
        },
        [addToast, dispatcher, history],
    )

    const handleClickGoogle = useCallback(async () => {
        const googleLogin = new GoogleLogin()

        let action: ACTION_TO_BE_DONE_LOGIN
        let firebaseToken: string | undefined

        try {
            const {
                actionToBeDone,
                firebaseToken: _firebaseToken,
            } = await googleLogin.login()

            action = actionToBeDone
            firebaseToken = _firebaseToken
        } catch (e) {
            addToast(e.message || e, { appearance: "error", autoDismiss: true })
            return
        }

        await handleRegisterProvider(action, firebaseToken)
    }, [handleRegisterProvider, addToast])

    const handleClickFacebook = useCallback(async () => {
        const facebookLogin = new FacebookLogin()

        let action: ACTION_TO_BE_DONE_LOGIN
        let firebaseToken: string | undefined

        try {
            const {
                actionToBeDone,
                firebaseToken: _firebaseToken,
            } = await facebookLogin.login()

            action = actionToBeDone
            firebaseToken = _firebaseToken
        } catch (e) {
            addToast(e.message || e, { appearance: "error", autoDismiss: true })
            return
        }

        await handleRegisterProvider(action, firebaseToken)
    }, [handleRegisterProvider, addToast])

    if (width < 700) {
        return (
            <Suspense fallback={<Fallback />}>
                <RegisterMobile
                    bank={bank}
                    firstname={firstname}
                    mail={mail}
                    name={name}
                    password={password}
                    setBank={setBank}
                    setFirstname={setFirstname}
                    setMail={setMail}
                    setName={setName}
                    setPassword={setPassword}
                    onSubmit={handleSubmit}
                    registerState={registerState}
                    handleClickFacebook={handleClickFacebook}
                    handleClickGoogle={handleClickGoogle}
                />
            </Suspense>
        )
    }

    return (
        <Suspense fallback={<Fallback />}>
            <RegisterDesktop
                bank={bank}
                firstname={firstname}
                mail={mail}
                name={name}
                password={password}
                setBank={setBank}
                setFirstname={setFirstname}
                setMail={setMail}
                setName={setName}
                setPassword={setPassword}
                onSubmit={handleSubmit}
                registerState={registerState}
                handleClickFacebook={handleClickFacebook}
                handleClickGoogle={handleClickGoogle}
            />
        </Suspense>
    )
}

export default Register

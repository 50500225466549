import React from "react"
import styles from "./Menu.module.scss"
import Logo from "assets/logo/N3.svg"
import { FiSettings } from "react-icons/fi"
import { ItemsType, MenuProps } from "./menu.types"
import { Link } from "react-router-dom"
import { routes } from "helpers/routes"

export const MenuDesktop = <T extends ItemsType>({
    items,
    onChange,
    value,
}: MenuProps<T>) => {
    return (
        <div className={styles.rootDesktop}>
            <nav>
                <div className={styles.leftMenu}>
                    <div className={styles.planifyLogo}>
                        <img src={Logo} alt="Planify Logo" />
                    </div>
                    <div className={styles.planifyTitle}>Planify</div>
                    {Object.keys(items).map((itemKey: keyof T & string) => {
                        const Icon = items[itemKey].icon
                        const active = value && value === itemKey
                        return (
                            <div
                                key={itemKey}
                                className={`${styles.item} ${
                                    active ? styles.active : ""
                                }`}
                                onClick={() => onChange(itemKey)}
                            >
                                {Icon && (
                                    <div
                                        className={`${styles.icon} ${
                                            active ? styles.active : ""
                                        }`}
                                    >
                                        {Icon}
                                    </div>
                                )}
                                <div className={styles.itemElm}>
                                    {items[itemKey].value}
                                    <div
                                        className={`${styles.underline} ${
                                            active ? styles.active : ""
                                        }`}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
                <Link className={styles.rightMenu} to={routes.app.settings.index}>
                    <FiSettings />
                </Link>
            </nav>
        </div>
    )
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EVENT_SOURCE = void 0;
var EVENT_SOURCE;
(function (EVENT_SOURCE) {
    EVENT_SOURCE["BRIDGE"] = "BRIDGE";
    EVENT_SOURCE["CSV"] = "CSV";
    EVENT_SOURCE["DEFAULT"] = "DEFAULT";
    EVENT_SOURCE["USER"] = "USER";
})(EVENT_SOURCE = exports.EVENT_SOURCE || (exports.EVENT_SOURCE = {}));

import React, { useState, useEffect, useMemo, useCallback } from "react"
import styles from "./AddEvent.module.scss"
import { FiArrowLeft, FiArrowRight, FiX } from "react-icons/fi"
import { AddEventSide } from "./add-event-side/AddEventSide"
import { EventFactory } from "helpers/events/event-factory"
import { EventInterface } from "@planify/lib-type"
import { Fallback } from "assets/fallback"
import { useSagaSubscribe, SAGA_TYPE, sagaDispatch } from "helpers/saga"
import { CalendarHelper } from "helpers/calendar"
import { EventsController } from "controllers/events/events"
import { RecurringExpenseController } from "controllers/recurring-expenses/recurring-expenses"
import { recurringsToEvents, getColorFromAmount } from "helpers/events"
import moment from "moment"

interface props {
    day: number
    month: number
    year: number
    onChange?: () => void
}

export const AddEvent: React.FC<props> = ({ day, month, year, onChange }) => {
    const [focusDay, setFocusDay] = useState(day)
    const [focusMonth, setFocusMonth] = useState(month)
    const [focusYear, setFocusYear] = useState(year)

    const focusDate = useMemo(() => {
        const nowDate = moment.utc()
        nowDate.set("year", focusYear)
        nowDate.set("month", focusMonth)
        nowDate.set("date", focusDay)
        nowDate.startOf("day")
        return nowDate.toDate()
    }, [focusYear, focusMonth, focusDay])

    const [events, setEvents] = useState<EventInterface[]>([])
    const [loading, setLoading] = useState(false)

    const getAllEvents = useCallback(async () => {
        setLoading(true)
        const eventsDataPromise = new EventFactory().getAllEventOfDay(
            focusDay,
            focusMonth,
            focusYear,
        )

        const recurringPromise = new RecurringExpenseController().allRecurringExpensesFromUser()

        const eventsData = await eventsDataPromise
        const recurrings = await recurringPromise

        const startDate = moment()
        startDate.set("year", focusYear)
        startDate.set("month", focusMonth)
        startDate.set("date", focusDay)
        startDate.startOf("day")

        const endDate = startDate.clone().add(1, "day")

        const eventsFromRecurring = await recurringsToEvents(
            recurrings,
            startDate,
            endDate,
        )

        setEvents([...eventsData, ...eventsFromRecurring])
        setLoading(false)
    }, [focusDay, focusMonth, focusYear])

    useEffect(() => {
        getAllEvents()
    }, [getAllEvents])

    useSagaSubscribe(
        SAGA_TYPE.CALENDAR_EVENT_UPDATE,
        useCallback(
            payload => {
                if (
                    CalendarHelper.dateMatch(
                        focusYear,
                        focusMonth,
                        focusDay,
                        payload.year,
                        payload.month,
                        payload.day,
                    )
                ) {
                    getAllEvents()
                }
            },
            [focusDay, getAllEvents, focusMonth, focusYear],
        ),
    )

    const setFocusAllDate = (newDate: Date) => {
        setFocusDay(newDate.getDate())
        setFocusMonth(newDate.getMonth())
        setFocusYear(newDate.getFullYear())
    }

    const handleNextDay = () => {
        const clonedDate = new Date(focusDate.getTime())
        clonedDate.setDate(clonedDate.getDate() + 1)
        setFocusAllDate(clonedDate)
    }

    const handlePreviousDay = () => {
        const clonedDate = new Date(focusDate.getTime())
        clonedDate.setDate(clonedDate.getDate() - 1)
        setFocusAllDate(clonedDate)
    }

    const handleDeleteEvent = async (id: string, isRecurring: boolean) => {
        if (isRecurring) {
            await new RecurringExpenseController().deleteRecurringExpenseFromUser(id)
            sagaDispatch(SAGA_TYPE.CALENDAR_EVENT_UPDATE, {
                year: "*",
                month: "*",
                day: "*",
            })
        } else {
            await new EventsController().deleteEventFromUser(id)
            sagaDispatch(SAGA_TYPE.CALENDAR_EVENT_UPDATE, {
                year: focusYear,
                month: focusMonth,
                day: focusDay,
            })
        }
    }

    return (
        <div className={styles.root}>
            <div className="columns is-gapless">
                <div className="column is-4">
                    <div className={styles.left}>
                        <div className={styles.titleLeft}>Evénements</div>
                        <div className={styles.listEvents}>
                            <div className={styles.headerList}>Liste</div>
                            <div className={styles.itemsList}>
                                {loading && (
                                    <Fallback className={styles.loading} size={15} />
                                )}
                                {events.map(event => (
                                    <div className={styles.itemList} key={event.id}>
                                        <div
                                            className={styles.roundItem}
                                            style={{ backgroundColor: event.color }}
                                        />
                                        <div
                                            className={styles.titleItem}
                                            title={event.category}
                                        >
                                            {event.name}
                                        </div>
                                        <div
                                            className={styles.earnItem}
                                            style={getColorFromAmount(event.amount)}
                                        >
                                            {event.amount}€
                                        </div>
                                        <div
                                            className={styles.deleteEvent}
                                            onClick={() =>
                                                handleDeleteEvent(
                                                    event.id,
                                                    event.isRecurring || false,
                                                )
                                            }
                                        >
                                            <FiX />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={styles.navigation}>
                            <div
                                className={styles.previousDay}
                                onClick={handlePreviousDay}
                            >
                                <FiArrowLeft />
                                <div>Jour précédent</div>
                            </div>
                            <div className={styles.nextDay} onClick={handleNextDay}>
                                <div>Jour suivant</div>
                                <FiArrowRight />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column" style={{ overflow: "hidden" }}>
                    <div className={styles.right}>
                        <AddEventSide date={focusDate} />
                    </div>
                </div>
            </div>
        </div>
    )
}

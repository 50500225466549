import React, { useState, useEffect, useRef } from "react"
import ReactDOM from "react-dom"
import { BaseProps, useBaseProps } from "assets/helpers/base-component"
import styles from "./Modal.module.scss"
import { useDetectClickOutside, useKeyPressed } from "helpers/hooks"

const modalRoot = document.getElementById("app-modal")

enum ANIM_STATE {
    CLOSE,
    START,
    END,
}

interface props extends BaseProps {
    open?: boolean
    onClose?: () => void
}

export const Modal: React.FC<props> = ({ open, onClose, children, ...props }) => {
    const [animationStep, setAnimationStep] = useState<ANIM_STATE>(ANIM_STATE.CLOSE)
    const [divModal, setDivModal] = useState<HTMLDivElement>()

    useEffect(() => {
        const newDiv = document.createElement("div")
        setDivModal(newDiv)
        modalRoot?.appendChild(newDiv)

        return () => {
            setDivModal(undefined)
            modalRoot?.removeChild(newDiv)
        }
    }, [])

    useEffect(() => {
        if (
            (open && animationStep === ANIM_STATE.CLOSE) ||
            (!open && animationStep === ANIM_STATE.END)
        ) {
            setAnimationStep(ANIM_STATE.START)
        }
    }, [open, animationStep])

    useEffect(() => {
        if (animationStep === ANIM_STATE.START) {
            if (open) {
                setAnimationStep(ANIM_STATE.END)
            } else {
                setTimeout(() => setAnimationStep(ANIM_STATE.CLOSE), 300)
            }
        }
    }, [open, animationStep])

    const rootProps = useBaseProps(
        props,
        `${styles.root} ${
            animationStep === ANIM_STATE.END
                ? styles.active
                : animationStep === ANIM_STATE.START
                ? styles.begin
                : ""
        }`,
    )

    const modalRef = useRef(null)
    useDetectClickOutside(modalRef, open || false, () => {
        onClose && onClose()
    })
    useKeyPressed("Escape", () => onClose && onClose(), open || false)

    if (!divModal) {
        return <div></div>
    }

    return ReactDOM.createPortal(
        <div {...rootProps}>
            <div className={styles.modal} ref={modalRef}>
                {animationStep !== ANIM_STATE.CLOSE && children}
            </div>
        </div>,
        divModal,
    )
}

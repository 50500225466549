"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RECURRING_EXPENSE_GRANULARITY = void 0;
var RECURRING_EXPENSE_GRANULARITY;
(function (RECURRING_EXPENSE_GRANULARITY) {
    RECURRING_EXPENSE_GRANULARITY["DAYS"] = "DAYS";
    RECURRING_EXPENSE_GRANULARITY["WEEKS"] = "WEEKS";
    RECURRING_EXPENSE_GRANULARITY["MONTH"] = "MONTH";
    RECURRING_EXPENSE_GRANULARITY["YEARS"] = "YEARS";
})(RECURRING_EXPENSE_GRANULARITY = exports.RECURRING_EXPENSE_GRANULARITY || (exports.RECURRING_EXPENSE_GRANULARITY = {}));

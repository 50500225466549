import { AbstractController } from "controllers/abtract-controller"
import { UserInterface } from "@planify/lib-type"

export class AuthController extends AbstractController {
    protected path = "/api/auth"

    public async loginUser(token: string): Promise<string> {
        return (
            await this.axios.post(`${this.path}/login`, {
                token,
            })
        ).data
    }

    public async registerUser(
        token: string,
        user: Omit<UserInterface, "id" | "mail">,
    ): Promise<string> {
        return (
            await this.axios.post(`${this.path}/register`, { token, userModel: user })
        ).data
    }
}

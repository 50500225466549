import React from "react"
import styles from "./Fallback.module.scss"
import { CircularProgress } from "@material-ui/core"
import { BaseProps, useBaseProps } from "assets/helpers/base-component"

interface props extends BaseProps {
    size?: number
}

export const Fallback: React.FC<props> = ({ size, ...props }) => {
    const rootProps = useBaseProps(props, styles.spinner)

    return (
        <div {...rootProps}>
            <CircularProgress size={size} />
        </div>
    )
}

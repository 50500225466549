import Axios, { AxiosResponse } from "axios"
import { API_MAIN_SERVER_URL } from "config/globals"
import { JWT_ERROR } from "@planify/lib-errors"
import { saveToken } from "./save-token"
import { getToken } from "./get-token"
import { clearToken } from "./clear-token"

export class RefreshToken {
    private token: string
    constructor() {
        const token = getToken()
        if (!token) {
            throw Error("Can't get token")
        }
        this.token = token
    }

    public async refresh(): Promise<boolean> {
        let response: AxiosResponse<{ token?: string }>
        try {
            response = await Axios({
                method: "POST",
                data: {
                    token: this.token,
                },
                baseURL: API_MAIN_SERVER_URL,
                url: "/utils/jwt/refresh",
            })
        } catch (error) {
            if (
                error?.response?.status === 401 &&
                error?.response?.data?.code === JWT_ERROR.BAD_JWT_REFRESH
            ) {
                clearToken()
                return false
            }
            throw error
        }

        if (!response.data.token) {
            throw Error("Can't refresh your token. Please re-login")
        }

        saveToken(response.data.token)

        return true
    }
}

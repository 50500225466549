import { WrappedAxios } from "./wrapped-axios"
import axios from "axios"
import { API_MAIN_SERVER_URL } from "config/globals"

// LoggedAxios
export const Laxios = () => {
    return new WrappedAxios()
}

export const Paxios = () => {
    return axios.create({
        baseURL: API_MAIN_SERVER_URL,
    })
}

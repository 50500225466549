import { UserState, USER_ACTIONS, Actions, LOAD_STATE } from "./user.type"
import { Reducer } from "redux"

const initialState: UserState = {
    state: LOAD_STATE.NOT_LOADED,
}

export const userReducer: Reducer<UserState, Actions> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case USER_ACTIONS.FILL:
            return {
                state: LOAD_STATE.LOADED,
                ...action.payload,
            }
        case USER_ACTIONS.SET_ERROR:
            return {
                state: LOAD_STATE.ERROR,
            }

        case USER_ACTIONS.INIT:
            return initialState
        default:
            return state
    }
}

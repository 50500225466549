import { useMemo } from "react"
import { routes } from "helpers/routes"

const routeContructor = (
    path: string,
    objectPath: string | Record<string, any>,
    actualPath: string[],
): string[] | undefined => {
    if (typeof objectPath === "string") {
        if (path === objectPath) {
            return actualPath
        }
        return undefined
    } else {
        const objectList = Object.entries(objectPath)

        for (const objectData of objectList) {
            const newPath = routeContructor(path, objectData[1], [
                ...actualPath,
                objectData[0],
            ])

            if (newPath) {
                return newPath
            }
        }

        return undefined
    }
}

export const useRouteArray = (path: string): string[] | undefined => {
    const splitedPath = useMemo(() => {
        return routeContructor(path, routes.app, ["app"])
    }, [path])

    return splitedPath
}

import React from "react"
import { Link } from "react-router-dom"
import styles from "./Settings.module.scss"
import { FiHome, FiBarChart, FiCalendar } from "react-icons/fi"
import { routes } from "helpers/routes"
import { useWindowWidth } from "helpers/hooks"

export interface SettingsMenuType {
    currentPath: string
}

export const SettingsMenu = ({ currentPath }: SettingsMenuType) => {
    const itemMenu /*: ItemsType */ = {
        general: { value: "Géneral", icon: <FiHome /> },
        bank: { value: "Banque", icon: <FiBarChart /> },
        user: { value: "Profil", icon: <FiCalendar /> },
    }

    const width = useWindowWidth()

    if (width < 500) {
        return (
            <div className={styles.rootMobile}>
                <div className={styles.topBar}>
                    {(Object.keys(itemMenu) as Array<keyof typeof itemMenu>).map(
                        itemKey => {
                            const active = currentPath === itemKey
                            return (
                                <div
                                    key={itemKey}
                                    className={`${styles.itemMobile} ${
                                        active ? styles.active : ""
                                    }`}
                                >
                                    <div
                                        className={`${styles.itemElmMobile} ${
                                            active ? styles.active : ""
                                        }`}
                                    >
                                        <Link
                                            className={`${styles.link} ${
                                                active ? styles.active : ""
                                            }`}
                                            to={routes.app.settings[itemKey]}
                                        >
                                            {itemMenu[itemKey].value}
                                        </Link>
                                    </div>
                                </div>
                            )
                        },
                    )}
                </div>
            </div>
        )
    }

    return (
        <div className={styles.rootDesktop}>
            <div className={styles.leftBar}>
                {(Object.keys(itemMenu) as Array<keyof typeof itemMenu>).map(itemKey => {
                    const active = currentPath === itemKey
                    return (
                        <Link
                            to={routes.app.settings[itemKey]}
                            key={itemKey}
                            className={`${styles.itemDesktop} ${
                                active ? styles.active : ""
                            }`}
                        >
                            <div className={`${styles.itemElmDesktop}`}>
                                {itemMenu[itemKey].value}
                            </div>
                        </Link>
                    )
                })}
            </div>
        </div>
    )
}

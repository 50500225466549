import React, { useEffect, useState } from "react"
import { useWindowWidth } from "helpers/hooks"
import styles from "./Settings.module.scss"
import { UserController } from "controllers/users/users"
import { BANK_TYPE, UserInterface } from "@planify/lib-type"
import { banks as banksNames } from "helpers/banks"
import { auth } from "firebase/app"
import { useToasts } from "react-toast-notifications"
import { FiArrowRight } from "react-icons/fi"
import { Button, Dropdown, Input } from "assets"
import { useSelector } from "react-redux"
import { StoreType } from "redux/store"
import { LOAD_STATE } from "redux/user/user.type"
import { initRedux } from "helpers/user/init-redux"

export const UserInformationsSettings: React.FC = () => {
    const width = useWindowWidth()
    const { addToast } = useToasts()
    const [firstname, setFirstname] = useState("")
    const [bank, setBank] = useState<BANK_TYPE>()
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [reNewPassword, setReNewPassword] = useState("")

    const userInfos = useSelector<StoreType, StoreType["user"]>(state => state.user)

    useEffect(() => {
        if (userInfos.state !== LOAD_STATE.LOADED) {
            return
        }

        setFirstname(userInfos.firstName)
        setBank(userInfos.bank)
    }, [userInfos])

    const submitInfo = async () => {
        const userController = new UserController()

        const myInfos: UserInterface = await userController.getMe()
        if (banksNames.some(bankElm => bankElm.key === bank) && firstname) {
            try {
                await new UserController().updateUser({
                    id: myInfos.id,
                    firstName: firstname,
                    mail: myInfos.mail,
                    bank:
                        bank === banksNames[0].key
                            ? (banksNames[0].value as BANK_TYPE)
                            : (banksNames[1].value as BANK_TYPE),
                    currentAmount: myInfos.currentAmount,
                    dashboardOrder: myInfos.dashboardOrder,
                })

                addToast("Vos informations ont été mise à jour", {
                    autoDismiss: true,
                    appearance: "success",
                })

                await initRedux()
            } catch (e) {
                addToast("Une erreur s'est produite", {
                    autoDismiss: true,
                    appearance: "error",
                })
            }
        } else {
            addToast("Champs invalide", {
                autoDismiss: true,
                appearance: "warning",
            })
        }
    }

    const submitPassword = () => {
        if (
            oldPassword &&
            newPassword &&
            reNewPassword &&
            newPassword === reNewPassword
        ) {
            auth().onAuthStateChanged(function (user) {
                if (user) {
                    if (user.providerData[0]?.providerId !== "password") {
                        addToast(
                            "Vous ne pouvez pas modifier votre mot de passe car vous êtes connecté avec " +
                                user.providerData[0]?.providerId,
                            {
                                autoDismiss: true,
                                appearance: "warning",
                            },
                        )
                    } else {
                        user.updatePassword(newPassword)
                            .then(function () {
                                addToast("Vos informations ont été mise à jour", {
                                    autoDismiss: true,
                                    appearance: "success",
                                    onDismiss: () => {
                                        localStorage.removeItem("jwt_session")
                                        window.location.reload()
                                    },
                                })
                            })
                            .catch(function (error) {
                                addToast("Une erreur s'est produite", {
                                    autoDismiss: true,
                                    appearance: "error",
                                })
                            })
                    }
                } else {
                    addToast("Une erreur s'est produite lors de l'authentification", {
                        autoDismiss: true,
                        appearance: "error",
                    })
                    localStorage.removeItem("jwt_session")
                    window.location.reload()
                }
            })
        }
    }

    return (
        <div
            className={
                width < 500 ? styles.navItemMobileSettings : styles.navItemDesktopSettings
            }
        >
            <div className={styles.sectionInfo}>
                <Input
                    className={styles.inputSettings}
                    label="Prénom / Nom"
                    value={firstname}
                    onChange={setFirstname}
                />
                <Dropdown
                    className={styles.inputSettings}
                    options={banksNames}
                    onChange={setBank}
                    value={bank}
                >
                    {bank || "Votre banque"}
                </Dropdown>
                <Button icon={<FiArrowRight />} theme="primary" onClick={submitInfo}>
                    Valider
                </Button>
            </div>

            <div className={styles.sectionInfo}>
                <Input
                    type="password"
                    className={styles.inputSettings}
                    label="Ancien mot de passe"
                    value={oldPassword}
                    onChange={setOldPassword}
                />
                <Input
                    type="password"
                    className={styles.inputSettings}
                    label="Nouveau mot de passe"
                    value={newPassword}
                    onChange={setNewPassword}
                />
                <Input
                    type="password"
                    className={styles.inputSettings}
                    label="Répeter le nouveau mot de passe"
                    value={reNewPassword}
                    onChange={setReNewPassword}
                />
                <Button icon={<FiArrowRight />} theme="primary" onClick={submitPassword}>
                    Valider
                </Button>
            </div>
        </div>
    )
}

import { CSSProperties } from "react"

export interface BaseProps {
    className?: string
    style?: CSSProperties
}

export const useBaseProps = (props: BaseProps, className?: string) => {
    let applicableClass = ""
    if (props.className) {
        applicableClass += props.className
    }

    if (className) {
        if (applicableClass !== "") {
            applicableClass += " "
        }
        applicableClass += className
    }

    return {
        ...props,
        className: applicableClass,
    }
}

import { Actions, USER_ACTIONS } from "./user.type"
import { UserInterface } from "@planify/lib-type/build"

export const fillAction = (user: UserInterface): Actions => ({
    type: USER_ACTIONS.FILL,
    payload: user,
})

export const errorAction = (): Actions => ({
    type: USER_ACTIONS.SET_ERROR,
})

export const initAction = (): Actions => ({
    type: USER_ACTIONS.INIT,
})

import { combineReducers, createStore } from "redux"
import { userReducer } from "./user/user.reducer"
import { ConfigReducer } from "./config/config.reducer"
import { RegisterReducer } from "./register/register.reducer"

const reducers = combineReducers({
    user: userReducer,
    config: ConfigReducer,
    register: RegisterReducer,
})

export type StoreType = ReturnType<typeof reducers>

export const store = createStore(
    reducers,
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
)

import { AbstractRegister, ACTION_TO_BE_DONE } from "./abstract-register"
import { BANK_TYPE, UserInterface } from "@planify/lib-type"

export class MailRegister extends AbstractRegister {
    public async register(
        mail: string,
        password: string,
        firstname: string,
        bank: BANK_TYPE,
    ): Promise<ACTION_TO_BE_DONE> {
        try {
            const credentials = await this.auth().createUserWithEmailAndPassword(
                mail,
                password,
            )

            const userModelDTO: Omit<UserInterface, "id" | "mail"> = {
                firstName: firstname,
                bank: bank,
                currentAmount: 0,
                dashboardOrder: [],
            }

            return this.actionToBeDone(credentials, userModelDTO)
        } catch (e) {
            if (e?.code === "auth/email-already-in-use") {
                return ACTION_TO_BE_DONE.MAIL_ALREADY_EXISTS
            }

            return ACTION_TO_BE_DONE.ERROR
        }
    }
}

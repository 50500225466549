import React, { useEffect, useState, useMemo } from "react"
import styles from "./AddEventSide.module.scss"
import { Input, ColorPicker, DatePicker, Dropdown } from "assets/forms"
import { Button } from "assets/buttons"
import { FiCheck, FiChevronDown } from "react-icons/fi"
import { useToasts } from "react-toast-notifications"
import { EventsController } from "controllers/events/events"
import { RecurringExpenseController } from "controllers/recurring-expenses/recurring-expenses"
import { CATEGORY_TYPE, DAYS, MONTHS } from "@planify/lib-type"
import { sagaDispatch, SAGA_TYPE } from "helpers/saga"
import Reccuring, { RecurringState } from "./Reccuring"

const COLORS = ["#0a8266", "#00a47e", "#fada58", "#ffac14", "#da0e3d"] as const
const DEFAULT_COLOR = COLORS[1]

const CategoriesOptions = Object.values(CATEGORY_TYPE).map(category => ({
    key: category,
    value: category,
}))

interface props {
    date: Date
}

enum AMOUNT_STATE {
    NULL,
    PLUS,
    MINUS,
}

export const AddEventSide: React.FC<props> = ({ date }) => {
    const { addToast } = useToasts()
    const [title, setTitle] = useState("")
    const [color, setColor] = useState<string>(DEFAULT_COLOR)
    const [amountState, setAmountState] = useState<AMOUNT_STATE>(AMOUNT_STATE.MINUS)
    const [amountTotal, setAmountTotal] = useState("")
    const [openDatePicker, setOpenDatePicker] = useState(false)
    const [selectedDate, setSelectedDate] = useState(date)
    const [category, setCategory] = useState<CATEGORY_TYPE>()
    const [recurringState, setReccuringState] = useState<RecurringState>({
        isReccuring: false,
    })

    useEffect(() => {
        setSelectedDate(date)
    }, [date])

    const handleSubmit = async () => {
        if (!title) {
            addToast("Titre vide", { appearance: "error", autoDismiss: true })
            return
        }

        let amount = parseFloat(amountTotal) || 0
        amount = Math.trunc(amount * 100) / 100
        amount = Math.abs(amount)

        if (amountState === AMOUNT_STATE.MINUS) {
            amount *= -1
        }

        if (Number.isNaN(amount)) {
            addToast("Dépense / Revenu impossible", {
                appearance: "error",
                autoDismiss: true,
            })
            return
        }

        try {
            if (!recurringState.isReccuring) {
                await new EventsController().addEvent({
                    amount,
                    category: category || CATEGORY_TYPE.Autres,
                    date: selectedDate,
                    color: color,
                    name: title,
                })
            } else {
                if (recurringState.granularity <= 0) {
                    return
                }

                await new RecurringExpenseController().addRecurringExpenseToUser({
                    name: title,
                    amount,
                    beginDate: selectedDate,
                    color,
                    category: category || CATEGORY_TYPE.Autres,
                    granularity: recurringState.type,
                    eachGranularity: recurringState.granularity,
                })
            }
        } catch (e) {
            addToast("Erreur : " + e.message || e, {
                appearance: "error",
                autoDismiss: true,
            })
            return
        }

        addToast("Evénement ajouté !", {
            appearance: "success",
            autoDismiss: true,
        })

        setTitle("")
        setColor(DEFAULT_COLOR)
        setAmountState(AMOUNT_STATE.MINUS)
        setAmountTotal("")

        if (recurringState.isReccuring) {
            sagaDispatch(SAGA_TYPE.CALENDAR_EVENT_UPDATE, {
                day: "*",
                month: "*",
                year: "*",
            })
        } else {
            sagaDispatch(SAGA_TYPE.CALENDAR_EVENT_UPDATE, {
                month: selectedDate.getMonth(),
                year: selectedDate.getFullYear(),
                day: selectedDate.getDate(),
            })
        }
    }

    const [showAdvance, setShowAdvance] = useState(false)

    const isNowDate = useMemo(() => {
        return (
            date.getFullYear() === selectedDate.getFullYear() &&
            date.getMonth() === selectedDate.getMonth() &&
            date.getDate() === selectedDate.getDate()
        )
    }, [date, selectedDate])

    return (
        <div className={styles.root}>
            <div className={styles.title}>
                {DAYS[(date.getDay() + 6) % 7]} {date.getDate()} {MONTHS[date.getMonth()]}
            </div>
            <div className={styles.firstLine}>
                <div className="columns">
                    <div className="column">
                        <Input
                            label="Titre"
                            type="text"
                            small
                            className={styles.inputTitle}
                            value={title}
                            onChange={setTitle}
                            disableAnimation
                        />
                    </div>
                    <div className="column is-narrow">
                        <div className={styles.dateChoice}>
                            <div
                                className={`${styles.now} ${
                                    isNowDate ? styles.activeDate : ""
                                }`}
                                onClick={() => setSelectedDate(date)}
                            >
                                Ce jour
                            </div>
                            <div
                                className={`${styles.datePicker} ${
                                    !isNowDate ? styles.activeDate : ""
                                }`}
                                onClick={() => !openDatePicker && setOpenDatePicker(true)}
                            >
                                {isNowDate
                                    ? "Choisir une date"
                                    : `${selectedDate.getDate()}/${
                                          selectedDate.getMonth() + 1
                                      }/${selectedDate.getFullYear()}`}
                                <DatePicker
                                    open={openDatePicker}
                                    setOpen={open => setOpenDatePicker(open)}
                                    value={selectedDate}
                                    onChange={setSelectedDate}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="columns is-vcentered">
                    <div className="column is-narrow">
                        <ColorPicker
                            value={color}
                            options={COLORS}
                            onChange={color => setColor(color)}
                        />
                    </div>
                    <div className="column is-narrow">
                        <div className={styles.amountSetter}>
                            <div
                                className={`${styles.itemChoice} ${
                                    amountState === AMOUNT_STATE.PLUS ? styles.active : ""
                                }`}
                                onClick={() => setAmountState(AMOUNT_STATE.PLUS)}
                            >
                                +
                            </div>
                            <div
                                className={`${styles.itemChoice} ${
                                    amountState === AMOUNT_STATE.MINUS
                                        ? styles.active
                                        : ""
                                }`}
                                onClick={() => setAmountState(AMOUNT_STATE.MINUS)}
                            >
                                -
                            </div>
                            <input
                                className={styles.inputAmount}
                                type="number"
                                placeholder={
                                    amountState === AMOUNT_STATE.MINUS
                                        ? "Dépense"
                                        : "Revenu"
                                }
                                value={amountTotal}
                                onChange={e => setAmountTotal(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.category}>
                <Dropdown
                    options={CategoriesOptions}
                    maxHeight={200}
                    value={category}
                    onChange={newValue => setCategory(newValue)}
                    className={styles.dropdownCategory}
                    style={{
                        zIndex: 15,
                    }}
                >
                    Catégorie
                </Dropdown>
            </div>
            <div className={styles.advance}>
                <div
                    className={styles.advanceTrigger}
                    onClick={() => setShowAdvance(!showAdvance)}
                >
                    <div className={styles.advanceTriggerContent}>
                        <FiChevronDown
                            style={{
                                transform: `rotate(${showAdvance ? "-180" : "0"}deg)`,
                            }}
                        />
                        <div>Avancé</div>
                    </div>
                </div>
                {showAdvance && (
                    <Reccuring
                        recurringState={recurringState}
                        onChange={newVal => setReccuringState(newVal)}
                    />
                )}
            </div>
            <div style={{ flexGrow: 1 }} />
            <div className={styles.submit}>
                <Button theme="primary" icon={<FiCheck />} onClick={handleSubmit}>
                    Valider
                </Button>
            </div>
        </div>
    )
}

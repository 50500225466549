import { AbstractController } from "../abtract-controller"
import { RecurringExpenseInterface } from "@planify/lib-type"

export class RecurringExpenseController extends AbstractController {
    protected path = "recurring-expense"

    public async allRecurringExpensesFromUser(): Promise<RecurringExpenseInterface[]> {
        return this.doGetRequest("/")
    }

    public async recurringExpenseByIdByUser(
        id: string,
    ): Promise<RecurringExpenseInterface> {
        return this.doGetRequest(`/${id}`)
    }

    public async addRecurringExpenseToUser(
        recurringExpense: Omit<RecurringExpenseInterface, "id">,
    ): Promise<string> {
        return this.doPostRequest("/add", recurringExpense)
    }

    public async updateRecurringExpenseFromUser(
        recurringExpense: RecurringExpenseInterface,
    ): Promise<boolean> {
        return this.doPostRequest("/update", recurringExpense)
    }

    public async deleteRecurringExpenseFromUser(id: string): Promise<boolean> {
        return this.doDeleteRequest(`/${id}`)
    }
}

import React, { useState } from "react"
import styles from "./Menu.module.scss"
import { ItemsType, MenuProps } from "./menu.types"
import { FiMenu, FiX, FiSettings } from "react-icons/fi"
import { Link } from "react-router-dom"
import { routes } from "helpers/routes"
import { useSelector } from "react-redux"
import { StoreType } from "redux/store"
import { LOAD_STATE } from "redux/user/user.type"
import { signout } from "helpers/jwt/signout"

export const MenuMobile = <T extends ItemsType>({
    items,
    onChange,
    value,
}: MenuProps<T>) => {
    const [isOpen, setIsOpen] = useState(false)

    const name = useSelector<StoreType, string>(state => {
        if (state.user.state === LOAD_STATE.LOADED) {
            return state.user.firstName
        }

        return ""
    })

    const signOut = async () => {
        await signout()
    }

    return (
        <div className={styles.rootMobile}>
            <div className={styles.backMenu} style={{ height: isOpen ? "100vh" : 60 }}>
                <div className={styles.topMenu}>
                    <div className={styles.logo} onClick={() => setIsOpen(!isOpen)}>
                        {!isOpen ? <FiMenu /> : <FiX />}
                    </div>
                    <div className={styles.appName}>Planify</div>
                </div>
                <div className={styles.menuContain} style={{ opacity: isOpen ? 1 : 0 }}>
                    <div className={styles.user}>
                        <div className={styles.userName}>{name}</div>
                        <div className={styles.deconectButton} onClick={() => signOut()}>
                            Déconnexion
                        </div>
                    </div>
                    <div className={styles.items}>
                        {Object.keys(items).map((itemKey: keyof T & string) => {
                            const Icon = items[itemKey].icon
                            const active = value && value === itemKey
                            return (
                                <div
                                    key={itemKey}
                                    className={`${styles.itemElmList} ${
                                        active ? styles.active : ""
                                    }`}
                                    onClick={() => {
                                        onChange(itemKey)
                                        setIsOpen(false)
                                    }}
                                >
                                    {Icon && <div className={styles.iconElm}>{Icon}</div>}
                                    <div className={styles.elmItem}>
                                        {items[itemKey].value}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <Link
                        onClick={() => setIsOpen(false)}
                        className={styles.settings}
                        to={routes.app.settings.index}
                    >
                        <FiSettings />
                    </Link>
                </div>
            </div>
        </div>
    )
}

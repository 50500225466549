export class CalendarHelper {
    public static getNbDaysInMonth(month: number, year: number) {
        const dayAfterMonthStarted = new Date(year, month, 32)
        return 32 - dayAfterMonthStarted.getDate()
    }

    public static getNbDaysInMonthGivenDate(date: Date) {
        return this.getNbDaysInMonth(date.getMonth(), date.getFullYear())
    }

    /**
     * Lundi = 0
     * @param month
     * @param year
     */
    public static getIndexOfFirstDayOfMonth(month: number, year: number) {
        // Days are in english format. Sunday is first, monday second, and so on.
        const firstDayForEn = new Date(year, month, 1).getDay()
        const firstDayForFr = firstDayForEn - 1 === -1 ? 6 : firstDayForEn - 1
        return firstDayForFr
    }

    public static getIndexOfFirstDayOfMonthGivenDate(date: Date) {
        return this.getIndexOfFirstDayOfMonth(date.getMonth(), date.getFullYear())
    }

    /**
     * Check if the focus date is compatible with the subscribed day
     * @example 2020 02 * and 2020 * * are compatible
     * @param subYear
     * @param subMonth
     * @param subDay
     * @param focusYear
     * @param focusMonth
     * @param focusDay
     */
    public static dateMatch(
        subYear: number | "*",
        subMonth: number | "*",
        subDay: number | "*",
        focusYear: number | "*",
        focusMonth: number | "*",
        focusDay: number | "*",
    ) {
        if (subYear === "*" || focusYear === "*") {
            return true
        }

        if (subYear !== focusYear) {
            return false
        }

        if (subMonth === "*" || focusMonth === "*") {
            return true
        }

        if (subMonth !== focusMonth) {
            return false
        }

        if (subDay === "*" || focusDay === "*") {
            return true
        }

        return subDay === focusDay
    }
}

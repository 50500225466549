import React from "react"
import ReactDOM from "react-dom"
import "./index.scss"
import "antd/dist/antd.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { init } from "@sentry/browser"

if (process.env.NODE_ENV === "production") {
    init({
        dsn: "https://6d4c71ea4e384198a411b3f7ef4e2704@o382902.ingest.sentry.io/5241794",
    })
}

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister()

import React, { useCallback, useEffect, useMemo, useState } from "react"
import styles from "./Stats.module.scss"
import { PredictionController } from "controllers/predictions/predictions"
import { CATEGORY_TYPE } from "@planify/lib-type"

import { EventsController } from "controllers/events/events"
import Globals from "./widgets/globals/Globals"
import Health from "./widgets/health/Health"
import Categories from "./widgets/categories/Categories"

const GRANULARITY_MONTH = [
    {
        key: "ONE",
        value: "1 mois",
    },
    {
        key: "THREE",
        value: "3 mois",
    },
    {
        key: "SIX",
        value: "6 mois",
    },
    {
        key: "TWELVE",
        value: "12 mois",
    },
] as const

const Stats: React.FC = () => {
    const [globalPredictions, setGlogalPrediction] = useState<number[]>([])
    const [categoryPredictions, setCategoryPredictions] = useState<
        Record<string, number[]>
    >()
    const [sumPredicted, setSumPedicted] = useState<number>(0)
    const [granularity, setGranularity] = useState<
        typeof GRANULARITY_MONTH[number]["key"]
    >("ONE")
    const [selectedCategory, setSelectedCategory] = useState<number>(0)
    const [futurEventInfos, setFuturEventInfos] = useState<Record<string, number>>()

    const categories = Object.values(CATEGORY_TYPE)

    const fetchPredictions = useCallback(async () => {
        const oneYearAgo = new Date()

        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)
        oneYearAgo.setDate(1)

        const predictionController = new PredictionController()
        let global = await predictionController.getGlobalPredictions(oneYearAgo)

        if (!global) {
            global = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }

        const category = await predictionController.getCategoriesPredictions(oneYearAgo)

        setGlogalPrediction(global.map(sum => Math.abs(sum)))
        setCategoryPredictions(category)
    }, [])

    const fetchEventInfos = useCallback(async () => {
        const eventController = new EventsController()
        const date = new Date()

        date.setFullYear(date.getFullYear() + (date.getMonth() + 1) / 12)
        date.setMonth((date.getMonth() + 1) % 12)

        const amountByCategory = await eventController.expensesFromNowToDate(
            new Date(),
            date,
        )

        let sum = 0
        for (const value of Object.values(amountByCategory)) {
            sum += value
        }

        setSumPedicted(sum)
        setFuturEventInfos(amountByCategory)
    }, [])

    useEffect(() => {
        fetchPredictions()
        fetchEventInfos()
    }, [fetchEventInfos, fetchPredictions])

    const dataCategory = useMemo(() => {
        if (categoryPredictions) {
            if (categoryPredictions[categories[selectedCategory]]) {
                const data = categoryPredictions[categories[selectedCategory]].map(sum =>
                    Math.abs(sum),
                )
                return data
            } else {
            }
        } else {
            return []
        }
    }, [categories, categoryPredictions, selectedCategory])

    const fetchPredictedAmounts = useCallback(async () => {
        let increment = 1
        if (granularity === "ONE") {
            increment += 1
        } else if (granularity === "THREE") {
            increment += 3
        } else if (granularity === "SIX") {
            increment += 6
        } else if (granularity === "TWELVE") {
            increment += 12
        }
        const begin = new Date(Date.now())
        begin.setDate(1)
        begin.setFullYear(begin.getFullYear() + (begin.getMonth() + 1) / 12)
        begin.setMonth((begin.getMonth() + 1) % 12)
        const end = new Date()
        end.setDate(1)
        end.setFullYear(begin.getFullYear() + (begin.getMonth() + increment) / 12)
        end.setMonth((begin.getMonth() + increment) % 12)
        const amountByCategory = await new EventsController().expensesFromNowToDate(
            begin,
            end,
        )
        setFuturEventInfos(amountByCategory)
    }, [granularity])

    useEffect(() => {
        fetchPredictedAmounts()
    }, [fetchPredictedAmounts])

    return (
        <div className={styles.sectionLayout}>
            <div className={styles.layoutGlobal}>
                <div className={styles.widget}>
                    <Globals globalPredictions={globalPredictions} />
                </div>

                <div className={styles.widget}>
                    <Health
                        globalPredictions={globalPredictions}
                        sumPredicted={sumPredicted}
                    />
                </div>
                <div className={styles.widget}>
                    <Categories
                        categoryPredictions={categoryPredictions}
                        dataCategory={dataCategory}
                        futurEventInfos={futurEventInfos}
                        granularity={granularity}
                        selectedCategory={selectedCategory}
                        setGranularity={setGranularity}
                        setSelectedCategory={setSelectedCategory}
                    />
                </div>
            </div>
        </div>
    )
}

export default Stats

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringToCategoryType = exports.CATEGORY_TYPE_N26 = exports.CATEGORY_TYPE = void 0;
var CATEGORY_TYPE;
(function (CATEGORY_TYPE) {
    CATEGORY_TYPE["AssuranceFinances"] = "Assurance & Finances";
    CATEGORY_TYPE["Autres"] = "Autres";
    CATEGORY_TYPE["Courses"] = "Courses";
    CATEGORY_TYPE["DepensesEntreprise"] = "D\u00E9penses d'entreprise";
    CATEGORY_TYPE["Divertissement"] = "Divertissement";
    CATEGORY_TYPE["General"] = "General";
    CATEGORY_TYPE["Restaurants"] = "Restaurants";
    CATEGORY_TYPE["Revenus"] = "Revenus";
    CATEGORY_TYPE["Sante"] = "Sante";
    CATEGORY_TYPE["Services"] = "Services";
    CATEGORY_TYPE["ServicesPublics"] = "Services Publics";
    CATEGORY_TYPE["Shopping"] = "Shopping";
    CATEGORY_TYPE["Transferts"] = "Transferts";
    CATEGORY_TYPE["Transport"] = "Transport";
})(CATEGORY_TYPE = exports.CATEGORY_TYPE || (exports.CATEGORY_TYPE = {}));
var CATEGORY_TYPE_N26;
(function (CATEGORY_TYPE_N26) {
    CATEGORY_TYPE_N26["AbonnementsDonations"] = "Abonnements & Donations";
    CATEGORY_TYPE_N26["AssuranceFinances"] = "Assurance & Finances";
    CATEGORY_TYPE_N26["Autre"] = "Autres";
    CATEGORY_TYPE_N26["CASH26"] = "CASH26";
    CATEGORY_TYPE_N26["CoursesAlimentations"] = "Courses & Alimentation";
    CATEGORY_TYPE_N26["DepensesEntreprise"] = "D\u00E9penses d'entreprise";
    CATEGORY_TYPE_N26["FamilleAmis"] = "Famille & Amis";
    CATEGORY_TYPE_N26["LogementCharges"] = "Logement & Charges";
    CATEGORY_TYPE_N26["LoisirsDivertissements"] = "Loisirs & Divertissement";
    CATEGORY_TYPE_N26["MultimediaElectronique"] = "Multim\u00E9dia & Electronique";
    CATEGORY_TYPE_N26["ParrainageN26"] = "Parrainage N26";
    CATEGORY_TYPE_N26["RestaurantsBars"] = "Restaurants & Bars";
    CATEGORY_TYPE_N26["Retrait"] = "Retrait";
    CATEGORY_TYPE_N26["Revenus"] = "Revenus";
    CATEGORY_TYPE_N26["Salaire"] = "Salaire";
    CATEGORY_TYPE_N26["SantePharmacie"] = "Sant\u00E9 & Pharmacie";
    CATEGORY_TYPE_N26["Shopping"] = "Shopping";
    CATEGORY_TYPE_N26["TaxesAmendes"] = "Taxes & Amendes";
    CATEGORY_TYPE_N26["TransportVoiture"] = "Transport & Voiture";
    CATEGORY_TYPE_N26["VoyageVacance"] = "Voyages & Vacances";
    CATEGORY_TYPE_N26["Education"] = "Education";
    CATEGORY_TYPE_N26["Epargne"] = "Epargne";
})(CATEGORY_TYPE_N26 = exports.CATEGORY_TYPE_N26 || (exports.CATEGORY_TYPE_N26 = {}));
function stringToCategoryType(bankString) {
    const map = {};
    Object.values(CATEGORY_TYPE).forEach(v => (map[v.toString().toLowerCase()] = v));
    return map[bankString.toLowerCase()];
}
exports.stringToCategoryType = stringToCategoryType;

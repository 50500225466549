import {
    REGISTER_ACTIONS,
    RegisterState,
    RegisterActions,
    REGISTER_STATE,
} from "./register.types"
import { Reducer } from "redux"

const defaultState: RegisterState = {
    state: REGISTER_STATE.EMPTY,
}

export const RegisterReducer: Reducer<RegisterState, RegisterActions> = (
    state = defaultState,
    action,
) => {
    switch (action.type) {
        case REGISTER_ACTIONS.SET_TOKEN:
            return {
                state: REGISTER_STATE.TOKEN,
                token: action.payload.token,
                mail: action.payload.mail,
            }
        case REGISTER_ACTIONS.CLEAR_ALL:
            return defaultState
        case REGISTER_ACTIONS.SET_CREDENTIALS:
            return {
                state: REGISTER_STATE.CREDENTIALS,
                mail: action.payload.mail,
                password: action.payload.password,
            }
        default:
            return state
    }
}

import React from "react"
import { ApexOptions } from "apexcharts"
import ApexCharts from "react-apexcharts"
import { useTheme } from "helpers/hooks"

interface props {
    series: Required<ApexOptions>["series"]
}

const options: ApexOptions = {
    chart: {
        type: "line",
        toolbar: {
            show: false,
        },
        zoom: {
            enabled: false,
        },
    },
    stroke: {
        curve: "smooth",
        colors: ["#FDC04C", "#00a47e"],
    },
    dataLabels: {
        enabled: false,
    },
    tooltip: {
        enabled: false,
    },
    grid: {
        show: true,
    },
    legend: {
        show: false,
    },
    yaxis: {
        show: true,
    },
    xaxis: {
        labels: {
            show: false,
        },
    },
}

export const DataLineWidget: React.FC<props> = ({ series }) => {
    const theme = useTheme()

    return (
        <ApexCharts
            type="line"
            options={{
                ...options,
                chart: {
                    ...options.chart,
                    foreColor: theme === "dark" ? "#ffffffb3" : "#373d3f",
                },
            }}
            series={series}
        />
    )
}

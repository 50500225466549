import React, { CSSProperties } from "react"

import styles from "./Input.module.scss"
import { BaseProps, useBaseProps } from "assets/helpers/base-component"

interface props extends BaseProps {
    label?: string
    value?: string
    type?: string
    onChange?: (newValue: string) => void
    labelStyle?: CSSProperties
    inputStyle?: CSSProperties
    disableAnimation?: boolean
    small?: boolean
}

export const Input: React.FC<props> = ({
    label,
    value,
    onChange,
    type,
    labelStyle,
    inputStyle,
    disableAnimation,
    small,
    ...props
}) => {
    const rootProps = useBaseProps(props, `${styles.root} ${small ? styles.small : ""}`)

    return (
        <div {...rootProps}>
            {label && !(disableAnimation && value) && (
                <div
                    className={styles.label + (value ? " " + styles.activeLabel : "")}
                    style={labelStyle}
                >
                    {label}
                </div>
            )}
            <input
                type={type || "text"}
                value={value}
                onChange={e => onChange && onChange(e.target.value)}
                style={inputStyle}
            />
        </div>
    )
}

import { BANK_TYPE } from "@planify/lib-type"

export const banks: {
    key: BANK_TYPE
    value: string
}[] = [
    {
        key: BANK_TYPE.N26,
        value: "N26",
    },
    {
        key: BANK_TYPE.Revolut,
        value: "Revolut",
    },
]

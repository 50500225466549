"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatesHelpers = void 0;
var DatesHelpers;
(function (DatesHelpers) {
    DatesHelpers.mapMonthToFrench = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
    ];
})(DatesHelpers = exports.DatesHelpers || (exports.DatesHelpers = {}));

import React, { useEffect, useState } from "react"
import styles from "./AddEventSide.module.scss"
import { RECURRING_EXPENSE_GRANULARITY } from "@planify/lib-type"
import { Input } from "assets/forms"

export type RecurringState =
    | {
          isReccuring: true
          type: RECURRING_EXPENSE_GRANULARITY
          granularity: number
      }
    | {
          isReccuring: false
      }

interface props {
    recurringState: RecurringState
    onChange: (value: RecurringState) => void
}

const Reccuring: React.FC<props> = ({ recurringState, onChange }) => {
    const [granularity, setGranularity] = useState<number>(() =>
        recurringState.isReccuring ? recurringState.granularity : 0,
    )

    useEffect(() => {
        if (recurringState.isReccuring && recurringState.granularity !== granularity) {
            onChange({
                ...recurringState,
                granularity,
            })
        }
    }, [granularity, recurringState, onChange])

    return (
        <div className={styles.advanceRoot}>
            <div className={styles.reccuringType}>
                <div>Répétition</div>
                <div className={styles.reccuringTypeChoice}>
                    <div
                        className={!recurringState.isReccuring ? styles.active : ""}
                        onClick={() => onChange({ isReccuring: false })}
                    >
                        Jamais
                    </div>
                    <div
                        className={
                            recurringState.isReccuring &&
                            recurringState.type === RECURRING_EXPENSE_GRANULARITY.DAYS
                                ? styles.active
                                : ""
                        }
                        onClick={() =>
                            onChange({
                                isReccuring: true,
                                type: RECURRING_EXPENSE_GRANULARITY.DAYS,
                                granularity,
                            })
                        }
                    >
                        Par jours
                    </div>
                    <div
                        className={
                            recurringState.isReccuring &&
                            recurringState.type === RECURRING_EXPENSE_GRANULARITY.WEEKS
                                ? styles.active
                                : ""
                        }
                        onClick={() =>
                            onChange({
                                isReccuring: true,
                                type: RECURRING_EXPENSE_GRANULARITY.WEEKS,
                                granularity,
                            })
                        }
                    >
                        Par semaines
                    </div>
                    <div
                        className={
                            recurringState.isReccuring &&
                            recurringState.type === RECURRING_EXPENSE_GRANULARITY.MONTH
                                ? styles.active
                                : ""
                        }
                        onClick={() =>
                            onChange({
                                isReccuring: true,
                                type: RECURRING_EXPENSE_GRANULARITY.MONTH,
                                granularity,
                            })
                        }
                    >
                        Par mois
                    </div>
                    <div
                        className={
                            recurringState.isReccuring &&
                            recurringState.type === RECURRING_EXPENSE_GRANULARITY.YEARS
                                ? styles.active
                                : ""
                        }
                        onClick={() =>
                            onChange({
                                isReccuring: true,
                                type: RECURRING_EXPENSE_GRANULARITY.YEARS,
                                granularity,
                            })
                        }
                    >
                        Par années
                    </div>
                </div>
            </div>
            {recurringState.isReccuring && (
                <div>
                    <Input
                        type="number"
                        label="Répétition tout les"
                        small
                        value={granularity === 0 ? "" : granularity.toString()}
                        onChange={val => {
                            let newVal = parseInt(val, 10)
                            if (isNaN(newVal)) {
                                newVal = 0
                            }

                            setGranularity(newVal)
                        }}
                    />
                </div>
            )}
        </div>
    )
}

export default Reccuring

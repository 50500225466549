export interface ConfigState {
    isDarkMode: boolean
}

export enum CONFIG_ACTIONS {
    TOOGLE_DARK_MODE = "CONFIG/TOOGLE_DARL_MODE",
}

interface ChangeDarkmodeType {
    type: CONFIG_ACTIONS.TOOGLE_DARK_MODE
}

export type Actions = ChangeDarkmodeType

import { useEffect } from "react"
import { SAGA_TYPE, SagaPayloadType } from "./types"
import { sagaSource } from "./store"
import { filter } from "rxjs/operators"

export const useSagaSubscribe = <T extends SAGA_TYPE>(
    eventType: T,
    fnSubscribe: (payload: SagaPayloadType[T]) => void,
) => {
    useEffect(() => {
        const sub = sagaSource.pipe(filter(ev => ev.type === eventType)).subscribe(ev => {
            fnSubscribe(ev.payload)
        })

        return () => {
            sub.unsubscribe()
        }
    }, [eventType, fnSubscribe])
}

export const sagaDispatch = <T extends SAGA_TYPE>(
    eventType: T,
    payload: SagaPayloadType[T],
) => {
    sagaSource.next({ type: eventType, payload })
}

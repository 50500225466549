import { AbstractController } from "controllers/abtract-controller"
import { UserInterface } from "@planify/lib-type"

export class UserController extends AbstractController {
    protected path = "users"

    public async getMe(): Promise<UserInterface> {
        return this.doGetRequest("/me")
    }

    public async updateUser(user: UserInterface): Promise<UserInterface> {
        return this.doPostRequest("/updateUser", user)
    }
}

import { AbstractLogin, ACTION_TO_BE_DONE } from "./abstract-login"

export class MailLogin extends AbstractLogin {
    public async login(
        mail: string,
        password: string,
    ): Promise<{ actionToBeDone: ACTION_TO_BE_DONE; firebaseToken?: string }> {
        try {
            const credentials = await this.auth().signInWithEmailAndPassword(
                mail,
                password,
            )
            return this.actionToBeDone(credentials)
        } catch (e) {
            if (e.code === "auth/user-not-found") {
                return { actionToBeDone: ACTION_TO_BE_DONE.NOT_FOUND }
            }

            throw e
        }
    }
}

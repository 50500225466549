import React, { useEffect, useMemo, useState } from "react"
import { ToastProvider } from "react-toast-notifications"
import Router from "./pages/Router"
import { store } from "redux/store"
import { Provider } from "react-redux"
import { DarkModeContext, ThemeType } from "helpers/theme"
import "firebase/auth"
import "config/firebaseAuth"
import "bulma"

const setCssVar = (varName: string, value: string) => {
    document.documentElement.style.setProperty(varName, value)
}

function App() {
    const [isDarkMode, setIsDarkMode] = useState(store.getState().config.isDarkMode)

    useEffect(() => {
        setCssVar("--white-color", isDarkMode ? "#26272c" : "white")
        setCssVar("--font-color", isDarkMode ? "white" : "black")
        setCssVar("--bg-color", isDarkMode ? "#2b2e33" : "#F6F7FA")
        setCssVar("--bg-color-2", isDarkMode ? "#44484e" : "#ebeef7")
        setCssVar("--soft-color", isDarkMode ? "#363a3e" : "#d8f2e6")
        setCssVar(
            "--transparent-color",
            isDarkMode ? "rgba(255, 255, 255, 0.03)" : "rgba(0, 0, 0, 0.03)",
        )
        setCssVar(
            "--transparent-hover-color",
            isDarkMode ? "rgba(255, 255, 255, 0.07)" : "rgba(0, 0, 0, 0.07)",
        )
        setCssVar(
            "--font-color-2",
            isDarkMode ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.7)",
        )
        setCssVar(
            "--font-color-3",
            isDarkMode ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.5)",
        )
        setCssVar(
            "--border-color",
            isDarkMode ? "rgba(255, 255, 255, 0.03)" : "rgba(0, 0, 0, 0.05)",
        )
    }, [isDarkMode])

    useEffect(() => {
        const subscribeStore = store.subscribe(() => {
            const state = store.getState()

            if (state.config.isDarkMode !== isDarkMode) {
                setIsDarkMode(state.config.isDarkMode)
            }
        })

        return () => {
            subscribeStore()
        }
    }, [isDarkMode])

    const theme = useMemo((): ThemeType => (isDarkMode ? "dark" : "light"), [isDarkMode])

    return (
        <DarkModeContext.Provider value={theme}>
            <Provider store={store}>
                <ToastProvider>
                    <Router />
                </ToastProvider>
            </Provider>
        </DarkModeContext.Provider>
    )
}

export default App

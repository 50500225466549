import { initializeApp } from "firebase/app"

const firebaseConfig = {
    apiKey: "AIzaSyCt9-z6UWcZWOQ4QXCRPRgI_HnXW7mcfNM",
    authDomain: "planify-dfe40.firebaseapp.com",
    databaseURL: "https://planify-dfe40.firebaseio.com",
    projectId: "planify-dfe40",
    storageBucket: "planify-dfe40.appspot.com",
    messagingSenderId: "53621423883",
    appId: "1:53621423883:web:e22bd62d803ba2b8496c0e",
    measurementId: "G-Y4YJZK7V59",
}

initializeApp(firebaseConfig)

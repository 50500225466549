import React from "react"
import { Route, Redirect, useLocation } from "react-router-dom"
import { UserInformationsSettings } from "./UserInformationsSettings"
import { BankInformationsSettings } from "./BankInformationsSettings"
import { GeneralSettings } from "./GeneralSettings"
import { SettingsMenu } from "./SettingsMenu"
import styles from "./Settings.module.scss"
import { routes } from "helpers/routes"
import { useWindowWidth } from "helpers/hooks"

const mapRouteToSettingsPage = {
    [routes.app.settings.bank]: "bank",
    [routes.app.settings.general]: "general",
    [routes.app.settings.user]: "user",
} as const

export const Settings = () => {
    const location = useLocation().pathname

    const width = useWindowWidth()

    const pathLocation = mapRouteToSettingsPage[location] || "general"

    return (
        <div className={width > 500 ? styles.settingsDesktop : styles.settingsMobile}>
            <SettingsMenu currentPath={pathLocation} />

            <Route path={routes.app.settings.user}>
                <UserInformationsSettings />
            </Route>
            <Route path={routes.app.settings.bank}>
                <BankInformationsSettings />
            </Route>
            <Route path={routes.app.settings.general}>
                <GeneralSettings />
            </Route>
            <Route path={routes.app.settings.index} exact>
                <Redirect to={routes.app.settings.general} />
            </Route>
        </div>
    )
}

import React, { ChangeEvent } from "react"
import { useWindowWidth } from "helpers/hooks"
import styles from "./Settings.module.scss"
import { CsvEventController } from "controllers/csv-event/csv-event"
import { Button } from "assets"
import { useToasts } from "react-toast-notifications"
import { FiUpload } from "react-icons/fi"
import { BridgeController } from "controllers/bridge/bridge"
import { Laxios } from "helpers/axios"

export const BankInformationsSettings = () => {
    const width = useWindowWidth()
    const { addToast } = useToasts()

    const submit = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e && e.target && e.target.files && e.target.files.length) {
            const csv = await e.target.files[0].text()
            const result = await new CsvEventController().uploadCsv(csv)
            if (result) {
                addToast("Vos informations ont été mise à jour", {
                    autoDismiss: true,
                    appearance: "success",
                })
            } else {
                addToast("Une erreur s'est produite", {
                    autoDismiss: true,
                    appearance: "error",
                })
            }
        }
    }

    const addBank = async () => {
        const url = await new BridgeController().addAccount()

        if (!url) {
            addToast("Someting went wrong", { appearance: "error", autoDismiss: true })
            return
        }

        window.open(url, "_blank", "noopener")
    }

    const fetchUser = async () => {
        await new BridgeController().fetchUser()
    }

    const deleteBridge = async () => {
        await Laxios().apply(axios => axios.delete("/bridge/delete-user"))
    }

    return (
        <div
            className={
                width < 500 ? styles.navItemMobileSettings : styles.navItemDesktopSettings
            }
        >
            <div className={styles.rootBanks}>
                <div className={styles.csv}>
                    <label className={styles.label}>
                        <div className={styles.inputCSVButton}>
                            <FiUpload />
                        </div>
                        <input
                            type={"file"}
                            accept=".csv"
                            onChange={submit}
                            className={styles.inputCSV}
                            id="csv-input"
                        />
                    </label>
                    <Button>Envoyer un CSV</Button>
                </div>

                <div className={styles.bridge}>
                    <div className={styles.title}>Banques</div>
                    <div className={styles.itemBridge}>
                        <Button onClick={addBank}>Ajouter une banque</Button>
                    </div>
                    <div className={styles.itemBridge}>
                        <Button onClick={fetchUser}>Synchroniser le calendrider</Button>
                    </div>
                    <div className={styles.itemBridge}>
                        <Button onClick={deleteBridge}>Delete bridge</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

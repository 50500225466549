import { UserInterface } from "@planify/lib-type/build"

export enum USER_ACTIONS {
    FILL = "USER/FILL",
    SET_ERROR = "USER/SET_ERROR",
    INIT = "USER/INIT",
}

export enum LOAD_STATE {
    NOT_LOADED,
    LOADED,
    ERROR,
}

interface FilledUSerState extends UserInterface {
    state: LOAD_STATE.LOADED
}

interface EmptyUserState {
    state: LOAD_STATE.NOT_LOADED
}

interface ErrorState {
    state: LOAD_STATE.ERROR
}

export type UserState = FilledUSerState | EmptyUserState | ErrorState

interface FillAction {
    type: USER_ACTIONS.FILL
    payload: UserInterface
}

interface ErrorAction {
    type: USER_ACTIONS.SET_ERROR
}

interface InitAction {
    type: USER_ACTIONS.INIT
}

export type Actions = FillAction | ErrorAction | InitAction

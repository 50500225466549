import { useEffect, useState } from "react"
import { SAGA_TYPE, useSagaSubscribe } from "helpers/saga"

let allSubscribed = new Array<{
    check: (e: MouseEvent) => Promise<boolean>
    trigger: () => void
}>()

window.addEventListener("click", async e => {
    const resultMapProm = allSubscribed.map(async subscribeFn => {
        return {
            valid: await subscribeFn.check(e),
            trigger: subscribeFn.trigger,
        }
    })

    const resultMap = await Promise.all(resultMapProm)

    resultMap.forEach(({ valid, trigger }) => {
        if (valid) {
            trigger()
        }
    })
})

const subscribe = (
    checkIfElementIsNotInside: (e: MouseEvent) => Promise<boolean>,
    executeIfElementIsNotInside: () => void,
) => {
    allSubscribed = [
        ...allSubscribed,
        { check: checkIfElementIsNotInside, trigger: executeIfElementIsNotInside },
    ]

    return () => {
        allSubscribed = allSubscribed.filter(fn => fn.check !== checkIfElementIsNotInside)
    }
}

export const useDetectClickOutside = (
    ref: React.RefObject<any>,
    active: boolean,
    triggerFunction: () => void,
) => {
    const [countActive, setCountActive] = useState<Set<string>>(new Set())

    useSagaSubscribe(SAGA_TYPE.DISABLE_CLICK_OUTSIDE, ({ disable, id }) => {
        if (disable) {
            countActive.add(id)
            setCountActive(new Set(countActive))
        } else {
            countActive.delete(id)
            setCountActive(new Set(countActive))
        }
    })

    useEffect(() => {
        if (!active || countActive.size !== 0) {
            return
        }

        const unsubscribe = subscribe(async e => {
            return (
                ref.current &&
                !ref.current.contains(e.target) &&
                document.body.contains(e.target as any)
            )
        }, triggerFunction)

        return () => {
            unsubscribe()
        }
    }, [active, ref, triggerFunction, countActive])
}

import React from "react"
// import styles from "./Menu.module.scss"
import { ItemsType, MenuProps } from "./menu.types"
import { MenuDesktop } from "./Menu.desktop"
import { MenuMobile } from "./Menu.mobile"
import { useWindowWidth } from "helpers/hooks"

export const Menu = <T extends ItemsType>(props: MenuProps<T>) => {
    const width = useWindowWidth()
    if (width < 700) {
        return <MenuMobile {...props} />
    }

    return <MenuDesktop {...props} />
}

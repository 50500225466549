import { CATEGORY_TYPE } from "@planify/lib-type"
import { Dropdown } from "assets"
import { DataLineWidget } from "assets/charts"
import React from "react"

import styles from "./Categories.module.scss"

const GRANULARITY_MONTH = [
    {
        key: "ONE",
        value: "1 mois",
    },
    {
        key: "THREE",
        value: "3 mois",
    },
    {
        key: "SIX",
        value: "6 mois",
    },
    {
        key: "TWELVE",
        value: "12 mois",
    },
] as const

interface props {
    setSelectedCategory: (value: number) => void
    selectedCategory: number
    setGranularity: (value: typeof GRANULARITY_MONTH[number]["key"]) => void
    granularity: typeof GRANULARITY_MONTH[number]["key"]
    futurEventInfos: Record<string, number> | undefined
    categoryPredictions: Record<string, number[]> | undefined
    dataCategory: number[] | undefined
}

const categories = Object.values(CATEGORY_TYPE)

const Categories: React.FC<props> = ({
    setSelectedCategory,
    setGranularity,
    granularity,
    futurEventInfos,
    selectedCategory,
    categoryPredictions,
    dataCategory,
}) => {
    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div className={styles.title}>Prédictions par categories</div>
                <Dropdown
                    className={styles.inputSettings}
                    options={GRANULARITY_MONTH}
                    onChange={setGranularity}
                    value={granularity}
                >
                    {granularity}
                </Dropdown>
            </div>
            <div className={styles.categories}>
                {categories.map((category, id) => (
                    <div
                        className={`${styles.category} ${
                            selectedCategory === id ? styles.active : ""
                        }`}
                        key={id}
                        onClick={() => setSelectedCategory(id)}
                    >
                        {category}
                    </div>
                ))}
            </div>
            <div>
                <div className={styles.future}>
                    <div className={styles.futureHeader}>
                        Dépenses (
                        {GRANULARITY_MONTH.find(elm => elm.key === granularity)?.value ||
                            ""}
                        )
                    </div>
                    <div className={styles.futureData}>
                        {(futurEventInfos &&
                            futurEventInfos![categories[selectedCategory].toString()]) ||
                            0}
                        €
                    </div>
                </div>
                <div className={styles.diagramInfos}>
                    {categoryPredictions && dataCategory && (
                        <div className={styles.chart}>
                            <DataLineWidget
                                series={[
                                    {
                                        name: "Categories prediction",
                                        data: dataCategory,
                                    },
                                    {
                                        name: "Categories sum",
                                        data: dataCategory.slice(
                                            0,
                                            dataCategory.length - 3,
                                        ),
                                    },
                                ]}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
export default Categories

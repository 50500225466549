import { AbstractController } from "controllers/abtract-controller"

export class BridgeController extends AbstractController {
    protected path = "/bridge"

    public async addAccount(): Promise<string | undefined> {
        return this.doGetRequest("/add-account")
    }

    public async fetchUser(): Promise<void> {
        return this.doGetRequest("/fetch")
    }
}

import { auth } from "firebase/app"
import { AuthController } from "controllers/auth/auth"
import { saveToken } from "helpers/jwt/save-token"

export enum ACTION_TO_BE_DONE {
    SUCCESS = "SUCESS",
    REGISTER = "REGISTER",
    NOT_FOUND = "NOT_FOUND",
    ERROR = "ERROR",
}

export abstract class AbstractLogin {
    protected auth = auth

    constructor() {
        this.auth().languageCode = "fr"
    }

    public abstract login(
        ...args: any[]
    ): Promise<{ actionToBeDone: ACTION_TO_BE_DONE; firebaseToken?: string }>

    protected async actionToBeDone(
        loginPayload: auth.UserCredential,
    ): Promise<{ actionToBeDone: ACTION_TO_BE_DONE; firebaseToken?: string }> {
        if (!loginPayload.user) {
            throw Error("Missing error in response")
        }

        let _token: string | undefined

        try {
            _token = await loginPayload.user.getIdToken()
            const authApi = new AuthController()
            const token = await authApi.loginUser(_token)

            if (!token) {
                return {
                    actionToBeDone: ACTION_TO_BE_DONE.REGISTER,
                    firebaseToken: token,
                }
            }

            saveToken(token)

            return { actionToBeDone: ACTION_TO_BE_DONE.SUCCESS }
        } catch (e) {
            if (e.response?.status === 404) {
                return {
                    actionToBeDone: ACTION_TO_BE_DONE.REGISTER,
                    firebaseToken: _token,
                }
            }

            return { actionToBeDone: ACTION_TO_BE_DONE.ERROR }
        }
    }
}

/*

3 cases :

1/ Le gars n'est ni dans la db, ni dans firebase -> register
2/ Le gars n'est aps dans la DB, mais dans firebase -> register
3/ Le gars est dans la DB + forebase -> dashboard

*/

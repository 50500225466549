import React from "react"
import LoginBase from "./Login.base"
import styles from "./Login.module.scss"

const LoginMobile: React.FC = () => {
    return (
        <div className={styles.loginMobile}>
            <LoginBase />
        </div>
    )
}
export default LoginMobile

import { AbstractController } from "controllers/abtract-controller"

const validGranularity = ["daily", "monthly", "yearly"] as const

export class PredictionController extends AbstractController {
    protected path: string = "/api/predictions"

    public async getBalanceMonth(month: number, year: number): Promise<number> {
        return this.doGetRequest("/balanceMonth", {
            params: {
                month,
                year,
            },
        })
    }

    /**
     * Get balance by granularity
     * @param granularity The granularuty
     * @param pivotStartString The date where we start (format: YYYY-MM-DD)
     * @param pivotEndString The date where we end (format: YYYY-MM-DD)
     */
    public async getBalance(
        granularity: typeof validGranularity[number],
        pivotStartString: string,
        pivotEndString: string,
    ): Promise<Array<{ pivot: string; value: number }>> {
        return this.doGetRequest("/balance", {
            params: {
                granularity,
                pivotStart: pivotStartString,
                pivotEnd: pivotEndString,
            },
        })
    }

    public async getExpensesMonth(
        month: number,
        year: number,
    ): Promise<{ expenses: number; incomes: number }> {
        return this.doGetRequest("/expensesMonth", {
            params: {
                month,
                year,
            },
        })
    }

    public async getExpenses(
        granularity: typeof validGranularity[number],
        pivotStart: string,
        pivotEnd: string,
    ): Promise<Array<{ pivot: string; value: { expenses: number; incomes: number } }>> {
        return this.doGetRequest("/expenses", {
            params: {
                granularity,
                pivotStart,
                pivotEnd,
            },
        })
    }

    public async getGlobalPredictions(fromDate: Date): Promise<number[]> {
        return this.doGetRequest("/predictionsGlobal", {
            params: {
                fromDate,
            },
        })
    }

    public async getCategoriesPredictions(
        fromDate: Date,
    ): Promise<Record<string, number[]>> {
        return this.doGetRequest("/predictionsByCategories", {
            params: {
                fromDate,
            },
        })
    }
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringToBankType = exports.BANK_TYPE = void 0;
var BANK_TYPE;
(function (BANK_TYPE) {
    BANK_TYPE["N26"] = "N26";
    BANK_TYPE["Revolut"] = "Revolut";
})(BANK_TYPE = exports.BANK_TYPE || (exports.BANK_TYPE = {}));
function stringToBankType(bankString) {
    const map = {};
    Object.values(BANK_TYPE).forEach(v => (map[v.toString().toLowerCase()] = v));
    return map[bankString.toLowerCase()];
}
exports.stringToBankType = stringToBankType;

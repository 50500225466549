"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WIDGET_TYPE = void 0;
var WIDGET_TYPE;
(function (WIDGET_TYPE) {
    WIDGET_TYPE["SAFE_BOX_REMAINING"] = "SAFE_BOX_REMAINING";
    WIDGET_TYPE["WALLET_WIDGET"] = "WALLET_WIDGET";
    WIDGET_TYPE["CALENDAR_WIDGET"] = "CALENDAR_WIDGET";
    WIDGET_TYPE["BALANCE_WIDGET"] = "BALANCE_WIDGET";
    WIDGET_TYPE["BALANCE_GRAPH_WIDGET"] = "BALANCE_GRAPH_WIDGET";
    WIDGET_TYPE["INCOMES_WIDGET"] = "INCOMES_WIDGET";
    WIDGET_TYPE["EXPENSES_WIDGET"] = "EXPENSES_WIDGET";
})(WIDGET_TYPE = exports.WIDGET_TYPE || (exports.WIDGET_TYPE = {}));

import React from "react"

import styles from "./Landing.module.scss"

import { routes } from "helpers/routes"

import Logo from "assets/logo/Logo.svg"
import { Link } from "react-router-dom"
import { FiArrowRight } from "react-icons/fi"
import landingBack from "./landingBack.svg"
import software from "./soft.png"

const Landing: React.FC = () => {
    return (
        <div className={styles.root}>
            <div>
                <div className="container">
                    <div className={styles.header}>
                        <img src={Logo} className={styles.headerLogo} alt="Planify" />
                        <div className="is-flex-grow-1"></div>
                        <Link to={routes.login} className={styles.headerLink}>
                            Connexion
                        </Link>
                        <Link to={routes.register} className={styles.headerLink}>
                            Inscription
                        </Link>
                    </div>
                    <div className={styles.panel}>
                        <div className={styles.leftPanel}>
                            <div className={styles.panelTitle}>
                                Economisez au quotidien
                            </div>
                            <div className={styles.panelDescription}>
                                Gérez vos budgets via une interface simple et intuitive
                            </div>
                            <div className="is-flex-grow-1"></div>
                            <Link to={routes.register}>
                                <div className={styles.buttonPanel}>
                                    <div>Commencer</div>
                                    <FiArrowRight />
                                </div>
                            </Link>
                        </div>
                        <div
                            className={styles.rightPanel}
                            style={{ backgroundImage: `url(${landingBack})` }}
                        >
                            <img src={software} alt="Software" />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.subSection}>
                <div className="container">En construction...</div>
            </div>
        </div>
    )
}

export default Landing

import React, { lazy, Suspense, useEffect, useState, useMemo } from "react"
import styles from "./DatePicker.module.scss"
import { SAGA_TYPE, sagaDispatch } from "helpers/saga"
import moment from "moment"
import { CircularProgress } from "@material-ui/core"

let INSTANCE_NUMBER = 0
const INSTANCE_ID = "DATEPICKER"

const DatePickerAnt = lazy(() => import("./DatePicker.lazy"))

interface props {
    open?: boolean
    setOpen?: (open: boolean) => void
    value?: Date
    onChange?: (value: Date) => void
}

export const DatePicker: React.FC<props> = ({ open, setOpen, value, onChange }) => {
    const [instanceUid] = useState(`${INSTANCE_ID}-${INSTANCE_NUMBER++}`)

    useEffect(() => {
        if (open) {
            sagaDispatch(SAGA_TYPE.DISABLE_CLICK_OUTSIDE, {
                disable: true,
                id: instanceUid,
            })
        } else {
            sagaDispatch(SAGA_TYPE.DISABLE_CLICK_OUTSIDE, {
                disable: false,
                id: instanceUid,
            })
        }
    }, [open, instanceUid])

    const momentDate = useMemo(() => (value ? moment(value) : undefined), [value])

    if (!open) {
        return <div></div>
    }

    return (
        <div>
            <Suspense
                fallback={
                    <div className={styles.spinner}>
                        <CircularProgress />
                    </div>
                }
            >
                <DatePickerAnt
                    open={true}
                    onOpenChange={isOpen => {
                        setOpen && setOpen(isOpen)
                    }}
                    onChange={date => {
                        date && onChange && onChange(date.toDate())
                    }}
                    className={styles.antPicker}
                    value={momentDate}
                />
            </Suspense>
        </div>
    )
}

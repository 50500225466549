import React, { useState, useRef } from "react"

import styles from "./Dropdown.module.scss"
import { BaseProps, useBaseProps } from "assets/helpers/base-component"
import { FiChevronDown } from "react-icons/fi"
import { useDetectClickOutside } from "helpers/hooks"

interface props<TKey extends string | number> extends BaseProps {
    options?: Readonly<Array<{ key: TKey; value: string }>>
    value?: string
    onChange?: (newValue: TKey) => void
    maxHeight?: number
    children?: any
}

export const Dropdown = <TKey extends string | number>({
    children,
    options,
    value,
    onChange,
    maxHeight,
    ...props
}: props<TKey>) => {
    const rootProps = useBaseProps(props, styles.root)
    const dropdownRef = useRef<HTMLDivElement>(null)

    const [isOpen, setIsOpen] = useState<boolean>(false)
    useDetectClickOutside(dropdownRef, isOpen, () => setIsOpen(false))

    const dropdownValue =
        value && options ? options.find(elm => elm.key === value)?.value : undefined

    return (
        <div {...rootProps} ref={dropdownRef}>
            <button className={styles.button} onClick={() => setIsOpen(!isOpen)}>
                <div className={styles.text}>{dropdownValue || children}</div>
                <FiChevronDown
                    className={styles.icon + (isOpen ? " " + styles.active : "")}
                />
            </button>
            {isOpen && options && (
                <div
                    className={styles.drop}
                    style={{ maxHeight: maxHeight || undefined }}
                >
                    {options.map(option => (
                        <div
                            className={styles.option}
                            key={option.key}
                            onClick={() => {
                                setIsOpen(false)
                                onChange && onChange(option.key)
                            }}
                        >
                            {option.value}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

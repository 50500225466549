import { auth } from "firebase/app"
import { saveToken } from "helpers/jwt/save-token"
import { UserInterface } from "@planify/lib-type/build"
import { AuthController } from "controllers/auth/auth"

export enum ACTION_TO_BE_DONE {
    SUCCESS = "SUCESS",
    ERROR = "ERROR",
    MAIL_ALREADY_EXISTS = "MAIL_ALREADY_EXISTS",
}

export abstract class AbstractRegister {
    protected auth = auth

    constructor() {
        this.auth().languageCode = "fr"
    }

    public abstract register(...args: any[]): Promise<ACTION_TO_BE_DONE>

    protected async actionToBeDone(
        registerPayload: auth.UserCredential,
        userModelDTO: Omit<UserInterface, "id" | "mail">,
    ): Promise<ACTION_TO_BE_DONE> {
        if (!registerPayload.user) {
            throw Error("Missing error in response")
        }
        try {
            const _token = await registerPayload.user.getIdToken()
            return this.registerUsingToken(_token, userModelDTO)
        } catch (e) {
            return ACTION_TO_BE_DONE.ERROR
        }
    }

    protected async registerUsingToken(
        _token: string,
        userModelDTO: Omit<UserInterface, "id" | "mail">,
    ) {
        try {
            const authApi = new AuthController()
            const token = await authApi.registerUser(_token, userModelDTO)

            if (!token) {
                return ACTION_TO_BE_DONE.ERROR
            }

            saveToken(token)
            return ACTION_TO_BE_DONE.SUCCESS
        } catch (e) {
            return ACTION_TO_BE_DONE.ERROR
        }
    }
}

/*

3 cases :

1/ Le gars n'est ni dans la db, ni dans firebase -> register
2/ Le gars n'est aps dans la DB, mais dans firebase -> register
3/ Le gars est dans la DB + forebase -> dashboard

*/

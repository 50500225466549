const mapAmountToColor: Array<{
    key: number
    value: { color: string; backgroundColor: string }
}> = [
    {
        key: -1000,
        value: {
            color: "red",
            backgroundColor: "#ff00001f",
        },
    },
    {
        key: -200,
        value: {
            color: "#ff3c00",
            backgroundColor: "#ff3c001a",
        },
    },
    {
        key: -30,
        value: {
            color: "#ff8300",
            backgroundColor: "#ff830026",
        },
    },
    {
        key: -10,
        value: {
            color: "#ffca00",
            backgroundColor: "#ffca0024",
        },
    },
    {
        key: 0,
        value: {
            color: "#6f6f6f",
            backgroundColor: "#6f6f6f14",
        },
    },
    {
        key: 20,
        value: {
            color: "#7fe200",
            backgroundColor: "#7fe2002e",
        },
    },
    {
        key: 200,
        value: {
            color: "#0ab700",
            backgroundColor: "#0ab7001c",
        },
    },
    {
        key: 1000,
        value: {
            color: "#0081d0",
            backgroundColor: "#0081d01f",
        },
    },
]

export const getColorFromAmount = (
    amount: number,
): { color: string; backgroundColor: string } => {
    for (const elm of mapAmountToColor) {
        if (amount <= elm.key) {
            return elm.value
        }
    }

    return mapAmountToColor[mapAmountToColor.length - 1].value
}

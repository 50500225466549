import React, { lazy, Suspense, useCallback, useEffect, useState } from "react"
import { DashboardController } from "controllers/dashboard/dashboard"
import { useWindowWidth } from "helpers/hooks"
import { Fallback } from "assets"
import { IWidgetWithId } from "@planify/lib-type"
import { SAGA_TYPE, useSagaSubscribe } from "helpers/saga"
const DashboardMobile = lazy(() => import("./Dashboard.mobile"))
const DashboardDesktop = lazy(() => import("./Dashboard.desktop"))

const Dashboard: React.FC = () => {
    const width = useWindowWidth()

    const [widgets, setWidgets] = useState<{
        loading: boolean
        widgets?: IWidgetWithId[]
    }>({ loading: true })

    const [order, setOrder] = useState<string[]>()

    const handleRefreshDashboard = useCallback(async () => {
        const dashboardController = new DashboardController()

        const updatedWidgetsPromise = dashboardController.getList()
        const widgetOrderPromise = dashboardController.getDashboardOrder()

        let updatedWidgets = await updatedWidgetsPromise
        const widgetOrder = await widgetOrderPromise

        setOrder(widgetOrder)

        let orderedWidgets = new Array<IWidgetWithId>()

        for (const widgetId of widgetOrder) {
            const targetWidget = updatedWidgets.find(widget => widget.id === widgetId)

            if (targetWidget) {
                orderedWidgets = [...orderedWidgets, targetWidget]
                updatedWidgets = updatedWidgets.filter(widget => widget.id !== widgetId)
            }
        }

        orderedWidgets = [...orderedWidgets, ...updatedWidgets]

        setWidgets({ loading: false, widgets: orderedWidgets })
    }, [])

    useEffect(() => {
        setWidgets({
            widgets: [],
            loading: true,
        })

        handleRefreshDashboard()
    }, [handleRefreshDashboard])

    const handleChangeWidgetPosition = async (widgetId: string, position: number) => {
        if (!widgets.widgets || !order) {
            return
        }

        let newPosition = position

        if (newPosition < 0) {
            newPosition = 0
        }

        const newOrder = order.filter(id => id !== widgetId)
        newOrder.splice(newPosition, 0, widgetId)

        await new DashboardController().updateDashboardOrder(newOrder)
        handleRefreshDashboard()
    }

    useSagaSubscribe(SAGA_TYPE.WIDGET_UPDATE, () => handleRefreshDashboard())

    if (width < 700) {
        return (
            <Suspense fallback={<Fallback />}>
                <DashboardMobile
                    widgets={widgets.widgets}
                    loading={widgets.loading}
                    onUpdate={handleRefreshDashboard}
                />
            </Suspense>
        )
    }

    return (
        <Suspense fallback={<Fallback />}>
            <DashboardDesktop
                widgets={widgets.widgets}
                loading={widgets.loading}
                handleChangeWidgetPosition={handleChangeWidgetPosition}
                onUpdate={handleRefreshDashboard}
            />
        </Suspense>
    )
}

export default Dashboard

import { AxiosInstance } from "axios"
import { JWT_ERROR } from "@planify/lib-errors"
import { RefreshToken } from "helpers/jwt/refresh-token"
import { getAxiosConnectedInstance } from "./axios-instance"

/**
 * Wrapped Axios
 */
export class WrappedAxios {
    private axiosInstance: AxiosInstance

    constructor() {
        this.axiosInstance = getAxiosConnectedInstance()
    }

    /**
     * Apply a function to a axios instance
     * @param functionApply The function that takes an axios instance
     */
    public async apply<TReturn>(
        functionApply: (axiosInstance: AxiosInstance) => TReturn,
    ): Promise<TReturn extends Promise<infer U> ? U : TReturn> {
        let returnRequest: TReturn
        try {
            returnRequest = await functionApply(this.axiosInstance)
        } catch (axiosError) {
            if (
                axiosError?.response?.status === 401 &&
                axiosError?.response?.data?.code === JWT_ERROR.BAD_JWT
            ) {
                const tokenWasRefreshed = await new RefreshToken().refresh()
                if (!tokenWasRefreshed) {
                    throw Error("Can't refresh your token. Please re-login")
                }

                this.axiosInstance = getAxiosConnectedInstance()

                returnRequest = await functionApply(this.axiosInstance)
            } else {
                throw axiosError
            }
        }
        return returnRequest as any
    }
}

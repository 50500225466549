import React, { useMemo } from "react"
import styles from "./DayItem.module.scss"
import { EventInterface } from "@planify/lib-type"
import { getColorFromAmount } from "helpers/events"

interface props {
    day: number
    month: number
    year: number
    events: EventInterface[]
    onClick: () => void
}

const DayItem: React.FC<props> = ({ day, month, year, events, onClick }) => {
    const today = useMemo(() => {
        return new Date()
    }, [])

    const isToday = useMemo(() => {
        return (
            day === today.getDate() &&
            month === today.getMonth() &&
            year === today.getFullYear()
        )
    }, [today, day, month, year])

    const promptedEvents = useMemo(() => {
        return events.sort((a, b) => Math.abs(b.amount) - Math.abs(a.amount)).slice(0, 3)
    }, [events])

    return (
        <div
            className={`${styles.root}  ${isToday ? styles.green : ""}`}
            onClick={onClick}
        >
            <div className={`${styles.dayNumber} ${isToday ? styles.green : ""}`}>
                {day}
            </div>
            <div className={styles.eventList}>
                {promptedEvents.map(event => (
                    <div key={event.id} className={styles.eventItem}>
                        <div
                            className={styles.dot}
                            style={{ backgroundColor: event.color }}
                        ></div>
                        <div className={styles.eventName}>{event.name}</div>
                        <div
                            className={styles.amount}
                            style={getColorFromAmount(event.amount)}
                        >
                            {event.amount}€
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default DayItem

import React, { useState } from "react"
import { FiArrowRight, FiAperture, FiHome, FiBarChart, FiCalendar } from "react-icons/fi"

import { Button, Input, Dropdown, Menu } from "assets"

const itemMenu = {
    dashboard: { value: "Dashboard", icon: <FiHome /> },
    stats: { value: "Statistiques", icon: <FiBarChart /> },
    calendar: { value: "Calendrier", icon: <FiCalendar /> },
}

export default () => {
    const [input, setInput] = useState("")
    const [dropdown, setDropdown] = useState<string>()
    const [activeMenu, setActiveMenu] = useState<keyof typeof itemMenu>("dashboard")

    return (
        <div>
            <Menu
                items={itemMenu}
                onChange={newVal => setActiveMenu(newVal)}
                value={activeMenu}
            />
            <div style={{ padding: "0px 20px 5000px 20px" }}>
                <br />
                <br />
                <Button icon={<FiAperture />} theme="primary">
                    Bonjour à vous !
                </Button>
                <br />
                <br />
                <Button icon={<FiArrowRight />}>Bonjour à vous !</Button>
                <br />
                <br />
                <Input label="Bonjour" value={input} onChange={setInput} />
                <br />
                <br />
                <Dropdown
                    options={[
                        {
                            key: "Yolot",
                            value: "Cela est un test !",
                        },
                        { key: "qsd", value: "Hello World !" },
                    ]}
                    value={dropdown}
                    onChange={setDropdown}
                >
                    Dropdown
                </Dropdown>
            </div>
        </div>
    )
}

import React from "react"

import styles from "./Health.module.scss"
import { FiArrowDownRight, FiArrowUpRight, FiCpu, FiRotateCw } from "react-icons/fi"

interface props {
    globalPredictions: number[]
    sumPredicted: number
}

const Health: React.FC<props> = ({ globalPredictions, sumPredicted }) => {
    const isDown =
        globalPredictions[globalPredictions.length - 4] -
            globalPredictions[globalPredictions.length - 3] >=
        0

    return (
        <div className={styles.root}>
            <span className={styles.title}>Santé du compte</span>
            <div className={styles.healthList}>
                <div className={styles.listItem}>
                    <div className={styles.iconContainer}>
                        <FiRotateCw className={styles.icon} />
                    </div>
                    <div className={styles.itemText}>
                        <strong>30€</strong> de dépense récurrente par mois
                    </div>
                </div>
                <div className={styles.listItem}>
                    <div
                        className={`${styles.iconContainer} ${!isDown ? styles.red : ""}`}
                    >
                        {isDown ? (
                            <FiArrowDownRight className={styles.icon} />
                        ) : (
                            <FiArrowUpRight className={styles.icon} />
                        )}
                    </div>
                    <div className={styles.itemText}>
                        <strong>{isDown ? "Baisse " : "Hausse "}</strong> des dépenses le
                        mois prochain
                    </div>
                </div>
                <div className={styles.listItem}>
                    <div className={styles.iconContainer}>
                        <FiCpu className={styles.icon} />
                    </div>
                    <div className={styles.itemText}>
                        <strong>{sumPredicted}€</strong> de dépenses prévu le mois
                        prochain
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Health

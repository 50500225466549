import { AbstractController } from "controllers/abtract-controller"
import { IWidgetWithId, IWidget } from "@planify/lib-type"

export class DashboardController extends AbstractController {
    protected path = "dashboard"

    public async getList(): Promise<Array<IWidgetWithId>> {
        return this.doGetRequest("/list")
    }

    /**
     * Add a widget
     * @param widget The widget
     * @returns The id of the widget or undefined
     */
    public async addWidget(widget: IWidget): Promise<string | undefined> {
        return this.doPostRequest("/add", { widget })
    }

    public async getById(id: string): Promise<IWidgetWithId | undefined> {
        return this.doGetRequest(`/id/${id}`)
    }

    public async updateWidget(id: string, widget: IWidget): Promise<boolean> {
        return this.doPostRequest(`/id/${id}`, { widget })
    }

    public async delete(id: string): Promise<boolean> {
        return this.doDeleteRequest(`/id/${id}`)
    }

    public async getDashboardOrder(): Promise<string[]> {
        return this.doGetRequest("/order")
    }

    public async updateDashboardOrder(widgetOrder: string[]): Promise<boolean> {
        return this.doPostRequest("/order", {
            dashboardOrder: widgetOrder,
        })
    }
}

import React, { useEffect, useState } from "react"
import { useWindowWidth } from "helpers/hooks"
import LoginMobile from "./Login.mobile"
import styles from "./Login.module.scss"
import { pictures } from "config/pictures"
import LoginBase from "./Login.base"
import axios from "axios"

const Login: React.FC = () => {
    const width = useWindowWidth()
    const [isBackgroundLoaded, setIsBackgroundLoader] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (width >= 500) {
            if (!isBackgroundLoaded && !isLoading) {
                setIsLoading(true)
                axios
                    .get(pictures.background, { responseType: "document" })
                    .then(() => {
                        setTimeout(() => {
                            setIsBackgroundLoader(true)
                            setIsLoading(false)
                        }, 500)
                    })
                    .catch(() => {})
            }
        } else {
            if (isBackgroundLoaded) {
                setIsBackgroundLoader(false)
            }
        }
    }, [width, isBackgroundLoaded, isLoading])

    if (width < 500) {
        return <LoginMobile />
    }

    return (
        <div className={styles.rootDesktop}>
            <div className={styles.login}>
                <div className={styles.container}>
                    <LoginBase />
                </div>
            </div>
            <div
                className={styles.background}
                style={{ backgroundImage: `url(${pictures.background})` }}
            >
                <div
                    className={styles.coverBackground}
                    style={{ opacity: isBackgroundLoaded ? 0 : 1 }}
                ></div>
            </div>
        </div>
    )
}
export default Login

import { ACTION_TO_BE_DONE, AbstractLogin } from "./abstract-login"

export class FacebookLogin extends AbstractLogin {
    public async login(
        ...args: any[]
    ): Promise<{
        actionToBeDone: ACTION_TO_BE_DONE
        firebaseToken?: string | undefined
    }> {
        try {
            const facebookProvider = new this.auth.FacebookAuthProvider()
            const credentials = await this.auth().signInWithPopup(facebookProvider)
            return this.actionToBeDone(credentials)
        } catch (e) {
            throw e
        }
    }
}

import { Reducer } from "redux"
import { ConfigState, Actions, CONFIG_ACTIONS } from "./config.type"

export const DARK_MODE_KEY = "__DARK_MODE_ENABLE__"

const defaultState: ConfigState = {
    isDarkMode: localStorage.getItem(DARK_MODE_KEY) === "true",
}

export const ConfigReducer: Reducer<ConfigState, Actions> = (
    state = defaultState,
    action,
) => {
    switch (action.type) {
        case CONFIG_ACTIONS.TOOGLE_DARK_MODE:
            const setDarkMode = !state.isDarkMode
            localStorage.setItem(DARK_MODE_KEY, setDarkMode ? "true" : "false")
            return {
                ...state,
                isDarkMode: setDarkMode,
            }
        default:
            return state
    }
}

const appRoute = "/app"

export const routes = {
    index: "/",
    login: "/login",
    register: "/register",
    app: {
        index: appRoute,
        dashboard: `${appRoute}/dashboard`,
        stats: `${appRoute}/stats`,
        calendar: `${appRoute}/calendar`,
        settings: {
            index: `${appRoute}/settings`,
            user: `${appRoute}/settings/user`,
            bank: `${appRoute}/settings/bank`,
            general: `${appRoute}/settings/general`,
        },
    },
    assets: "/assets",
}

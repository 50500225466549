import React from "react"

import styles from "./Globals.module.scss"
import { DataLineWidget } from "assets/charts"

interface props {
    globalPredictions: number[]
}

const Globals: React.FC<props> = ({ globalPredictions }) => {
    return (
        <div className={styles.root}>
            <span className={styles.title}>Prédictions globales</span>
            <div className={styles.sectionGlobal}>
                <div className={styles.dataChart}>
                    <DataLineWidget
                        series={[
                            {
                                name: "Global prediction",
                                data: globalPredictions,
                            },
                            {
                                name: "tmp",
                                data: globalPredictions.slice(
                                    0,
                                    globalPredictions.length - 3,
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    )
}

export default Globals
